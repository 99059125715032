import { ErrorMessage } from "@hookform/error-message";
import { Button, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { checkInputValue } from "../../../helpers/helpers";
import { StyledRegister } from "./Register.styled";
import { RegisterModel, RegisterSchema } from "../../../models/register";
import { zodResolver } from "@hookform/resolvers/zod";
// import { DevTool } from "@hookform/devtools";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AuthContext";
import Metal from "../../../assets/images/auth/metal";


function Register() {

    const navigate = useNavigate();
    const { user, registerUser } = useAuth();

    const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm<RegisterModel>({
        resolver: zodResolver(RegisterSchema),
    });


    const register = async () => {

        // If All Forms have no errors, submit.
        await new Promise<void>((resolve, reject) => {
            handleSubmit((data) => {
                onSubmit(data);
                resolve();
            }, (errors) => {
                reject(errors);
                return;
            })();
        });
    }


    const onSubmit: SubmitHandler<RegisterModel> = async (data) => {

        console.log("Register onSubmit data: ", data);


        await registerUser(data);

        if (user) {
            navigate('/');
        }
    };

    return (

        <StyledRegister>
            <Metal />
            <Grid container sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <Grid item xs={11} md={9} lg={7} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", alignItems: "center", gap: 3 }} className="page">
                    <Typography variant="h3">הרשמה</Typography>
                    <form >

                        <div className="section column">
                            <Typography variant="subtitle1">פרטי משתמש</Typography>

                            <Controller
                                name={"user_first_name"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"user_first_name"}
                                        label={"שם פרטי"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["user_first_name"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"user_first_name"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"user_last_name"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"user_last_name"}
                                        label={"שם משפחה"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["user_last_name"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"user_last_name"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"user_email"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"user_email"}
                                        label={"אימייל"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["user_email"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"user_email"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"user_password"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"user_password"}
                                        label={"סיסמה"}
                                        type="password"

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["user_password"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"user_password"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"user_password_confirmation"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"user_password_confirmation"}
                                        label={"אימות סיסמה"}
                                        type="password"

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["user_password_confirmation"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"user_password_confirmation"} />}
                                    />
                                )}
                            />
                        </div>

                        <div className="section column">
                            <Typography variant="subtitle1">פרטי עסק</Typography>

                            <Controller
                                name={"business_name"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"business_name"}
                                        label={"שם העסק"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["business_name"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"business_name"} />}
                                    />
                                )}
                            />


                            <Controller

                                name="business_type"
                                control={control}

                                render={({ field: { onChange, value } }) => (
                                    <FormControl error={!!errors.business_type}>
                                        <InputLabel id="business_type-label">סוג העסק</InputLabel>
                                        <Select
                                            sx={{ minWidth: "120px" }}
                                            value={checkInputValue(value)}
                                            onChange={onChange}

                                            required={true}
                                            autoComplete="off"

                                        >
                                            <MenuItem value='עוסק פטור'>עוסק פטור</MenuItem>
                                            <MenuItem value='עוסק מורשה'>עוסק מורשה</MenuItem>
                                            <MenuItem value='חברה בע"מ'>חברה בע"מ</MenuItem>
                                        </Select>
                                        <FormHelperText><ErrorMessage errors={errors} name={'business_type'} /></FormHelperText>

                                    </FormControl>
                                )}
                            />
                            {/* <FormHelperText><ErrorMessage errors={errors} name={"business_type"} /></FormHelperText> */}

                            <Controller
                                name={"business_identity_number"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"business_identity_number"}
                                        label={"מס ע.מ."}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["business_identity_number"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"business_identity_number"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"business_address"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"business_address"}
                                        label={"כתובת העסק"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["business_address"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"business_address"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"business_city"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"business_city"}
                                        label={"יישוב"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["business_city"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"business_city"} />}
                                    />
                                )}
                            />

                            <Controller
                                name={"business_postal_code"}
                                control={control}
                                defaultValue={""}

                                render={({ field: { onChange, value } }) => (
                                    <TextField

                                        id={"business_postal_code"}
                                        label={"מיקוד"}

                                        value={checkInputValue(value)}
                                        onChange={onChange}

                                        required={true}
                                        autoComplete="off"

                                        error={errors?.["business_postal_code"] ? true : false}
                                        helperText={<ErrorMessage errors={errors} name={"business_postal_code"} />}
                                    />
                                )}
                            />
                        </div>

                        <Button variant="contained" disabled={isSubmitting} onClick={register} >הירשם</Button>
                        <Link onClick={() => { navigate("/login") }} className="login-link" sx={{ textDecoration: "none" }}>התחברות</Link>

                    </form>
                </Grid>
            </Grid>




            {/* <DevTool control={control} /> */}
        </StyledRegister>

    );
}

export default Register;