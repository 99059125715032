import { IconButton, Tooltip, Typography } from "@mui/material"
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import { Document, DocumentAdd, DocumentEdit } from "../../../../models/document";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import SettingsIcon from '@mui/icons-material/Settings';
import { DocumentStructure } from "../../../../models/document-structure";
import MuiInput from "../../../../components/mui-input/MuiInput";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';


// theme
import { useTheme } from "@emotion/react";
import { Account } from "../../../../models/account";
import { useLocation } from "react-router-dom";
import { DocumentSettings } from "../../../../models/document-settings";

//state properties:
interface LocationState {

    reference?: string | null;
    customer?: Account | null;
    document?: Document | null;
}

interface BankProps {

    method: "add" | "edit";

    documentForm: UseFormReturn<DocumentAdd> | UseFormReturn<DocumentEdit>;
    settings: DocumentSettings;
    structure: DocumentStructure[];

    browseBank: () => void;
    addBank: () => void;
    editBank: () => void;

    editAccountStructure: () => void;

    isDocumentClosed?: boolean;
}




function Bank({ method, documentForm, settings, structure, browseBank, addBank, editBank, editAccountStructure, isDocumentClosed }: BankProps) {


    const { state } = useLocation() as { state: LocationState; }; // state data from another page

    const theme = useTheme();

    function renderAccountField<T extends FieldValues>(field: DocumentStructure, documentForm: UseFormReturn<T>) {

        const isAccountNumberField = field.field_name === "customer.account_number";

        const shouldDisableCustomerAccountField = state?.reference || (!settings?.display_items && !settings?.display_works && settings?.display_payments && documentForm.getValues("customer.account_number" as Path<T>));
        const isCustomerField = field.type === "לקוח-ספק" && field.show_form;

        if (isCustomerField && isAccountNumberField && shouldDisableCustomerAccountField) {
            return <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired disabled />;
        }


        if (isCustomerField) {
            return <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired disabled={isDocumentClosed}/>;
        }

        // Return null if none of the conditions are met
        return null;
    }


    if (method === "add") {
        return (
            <div className="bank">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            פרטי בנק:
                        </Typography>

                        <Tooltip title="בחירת כרטיס בנק מתוך דפדוף" placement="top">
                            <IconButton onClick={browseBank} sx={{ color: theme.colors.main_color }}>
                                <AutoStoriesIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="הוספת כרטיס בנק" placement="top">
                            <span>
                                <IconButton onClick={addBank} disabled={!!state?.customer?.id || !!state?.document?.customer?.customer_id} sx={{ color: theme.colors.main_color }}>
                                    <BookmarkAddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="עריכת כרטיס בנק" placement="top">
                            <span>
                                <IconButton onClick={editBank} disabled={!state?.customer?.id && !state?.document?.customer?.customer_id} sx={{ color: theme.colors.main_color }}>
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="הגדרת שדות בנק" placement="top">
                            <IconButton onClick={editAccountStructure} sx={{ color: theme.colors.main_color }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="inputs">
                    {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => renderAccountField(field, documentForm as UseFormReturn<DocumentAdd>))}

                </div>
            </div>
        )
    }

    if (method === "edit") {
        return (
            <div className="bank">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            פרטי בנק:
                        </Typography>

                        <Tooltip title="בחירת כרטיס בנק מתוך דפדוף" placement="top">
                            <span>
                                <IconButton onClick={browseBank} sx={{ color: theme.colors.main_color }} disabled={isDocumentClosed}>
                                    <AutoStoriesIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="הוספת כרטיס בנק" placement="top">
                            <span>
                                <IconButton onClick={addBank} disabled={!!state?.customer?.id || !!state?.document?.customer?.customer_id || isDocumentClosed} sx={{ color: theme.colors.main_color }}>
                                    <BookmarkAddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="עריכת כרטיס בנק" placement="top">
                            <span>
                                <IconButton onClick={editBank} disabled={(!state?.customer?.id && !state?.document?.customer?.customer_id) || isDocumentClosed} sx={{ color: theme.colors.main_color }}>
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="הגדרת שדות בנק" placement="top">
                            <IconButton onClick={editAccountStructure} sx={{ color: theme.colors.main_color }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="inputs">
                    {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => renderAccountField(field, documentForm as UseFormReturn<DocumentEdit>))}

                </div>
            </div>
        )
    }

}

export default Bank