import styled from '@emotion/styled'

export const StyledDocument = styled.div`

    display: flex;
    flex-direction: column;
    gap: 25px;

    .title{
        display: flex;
        justify-content: space-between;

    }



    .details{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        gap: 15px;
    }

    .customer, .car{
        min-height: 200px;
        flex-grow: 1;
    }

    .head, .customer, .car, .works, .items, .payment, .invoices, .summary, .deposit, .bank{
        padding: 15px;
        background-color: ${({theme})=> theme.colors.section_color};
        border-radius: 15px;
    }

    .head{
        /* position: relative; */
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;

        .select-document{
            flex-grow: 1;
        }

        .inputs{
            flex-grow: 1;
            justify-content: end;
        }
    }

    .buttons{
        display: flex;
        gap: 15px;

        button{
            cursor: pointer;
        }
    }
    
    .inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: baseline;
    }

    table{
        width: 100%;
    }

    .document-number{
        /* position: absolute;
        right: 50%;
        transform: translateX(50%); */
    }

    .loading{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    table {

        border-collapse: separate;
        border-spacing: 0 10px; /* 10px space between rows */

        td{
            vertical-align: top;
        } 

        .row-number{
            vertical-align: middle;
        }
    }

    .delete-row{
        padding: 5px;
        vertical-align: middle;
    }

    .summary{
        display: flex;
        gap: 50px;
    }

    .summary-block{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .selected-documents-table{
        border-collapse: separate;
        border-spacing: 20px 0;
    }


    @media only screen and (max-width: 900px){
        .details{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 15px;
    }
    
    }
`