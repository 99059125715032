import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);

export const DesignSettingsSchema = z.object({
    
    // required
    id: z.number(),  
    business_id: z.number(),
    user_id: z.number(),
    highlight_color: z.string(),
    main_color: z.string(),
    input_text_color: z.string(),
    input_title_text_color: z.string(),
    input_border_color: z.string(),
    background_color: z.string(),
    section_color: z.string(),
    header_color: z.string(),

    created_at: z.string(),
    updated_at: z.string(),
    
});

export const DesignSettingsFormSchema = z.object({
      
    // required
    id: z.number(),  
    business_id: z.number(),
    user_id: z.number(),
    
    // required fields
    highlight_color: z.preprocess((val) => myPreProcess(val), z.string()),
    main_color: z.preprocess((val) => myPreProcess(val), z.string()),
    input_text_color: z.preprocess((val) => myPreProcess(val), z.string()),
    input_title_text_color: z.preprocess((val) => myPreProcess(val), z.string()),
    input_border_color: z.preprocess((val) => myPreProcess(val), z.string()),
    background_color: z.preprocess((val) => myPreProcess(val), z.string()),
    section_color: z.preprocess((val) => myPreProcess(val), z.string()),
    header_color: z.preprocess((val) => myPreProcess(val), z.string()),
});


export type DesignSettings = z.infer<typeof DesignSettingsSchema>
export type DesignSettingsForm = z.infer<typeof DesignSettingsFormSchema>
