import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentSettingsSchema = z.object({
     
     id: z.number(),
     business_id: z.number().optional(),
     type: z.string(),
     name: z.string().nullable(),
     short_name: z.string().nullable(),
     display_customer: z.boolean().nullable(),
     display_car: z.boolean().nullable(),
     display_works: z.boolean().nullable(),
     display_items: z.boolean().nullable(),
     display_payments: z.boolean().nullable(),
     circle: z.boolean().nullable(),
     accounting: z.boolean().nullable(),
     editable: z.boolean().nullable(),
     created_at: z.string(),
     updated_at: z.string(),

});

export const DocumentSettingsFormSchema = z.object({

     //inputs optional
     name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     short_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     display_customer: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_car: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_works: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_items: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_payment: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     circle: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     accounting: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),

});

export const DocumentSettingsSearchSchema = z.object({

     //inputs optional
     name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     short_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     display_works: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_payment: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     circle: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     accounting: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),

});

export type DocumentSettings = z.infer<typeof DocumentSettingsSchema>
export type DocumentSettingsForm = z.infer<typeof DocumentSettingsFormSchema>
export type DocumentSettingsSearch = z.infer<typeof DocumentSettingsSchema>