import axiosClient from "../axios-client";
import { Document, DocumentAdd, DocumentEdit } from "../models/document";

export const getDocuments = async (sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', load: string[] = [], body: object = {}, signal: AbortSignal): Promise<Document[]> => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.post(`/api/documents/search/${maxRecords}/${sortKey}/${sortMethod}${loadString}`, body, {
        signal: signal // Pass the signal to the request options
    });
    return response.data;
}

export const getOpenDocuments = async (accountId: number, signal: AbortSignal): Promise<Document[]> => {
    const response = await axiosClient.get(`/api/documents/open/${accountId}`, {
        signal: signal // Pass the signal to the request options
    });
    return response.data;
}

export const getDocumentById = async (id: number | string, load: string[] = []): Promise<Document> => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.get(`/api/documents/${id}${loadString}`);
    return response.data;
}

export const getNextDocumentNumber = async (documentSettingsId: number): Promise<number> => {
    const body = {
        document_settings: {
            id: documentSettingsId
        }
    };
    const response = await axiosClient.post(`/api/documents/next-number`, body);
    return response.data.next_document_index;
}

export const addDocument = async (document: DocumentAdd): Promise<Document> => {
    const response = await axiosClient.post(`/api/documents`, document);
    return response.data;
}

export const saveDocument = async (documentId: number, documentToSave: DocumentEdit): Promise<Document> => {
    const response = await axiosClient.patch(`/api/documents/${documentId}`, documentToSave);
    return response.data;
}