import { UseFormReturn } from "react-hook-form";
import { DocumentSettings } from "../../../../models/document-settings"
import { DocumentAdd, DocumentEdit } from "../../../../models/document";
import { Typography } from "@mui/material";

interface SummaryProps {

    method: "add" | "edit";

    documentForm: UseFormReturn<DocumentAdd> | UseFormReturn<DocumentEdit>;

    settings: DocumentSettings;
}

function Summary({ method, documentForm, settings }: SummaryProps) {

    if (method === "add") {

        documentForm = documentForm as UseFormReturn<DocumentAdd>;

        const {

            total,
            discount,
            vat,
            to_pay,
            balance,
            receipt,
            deposit,
            include_vat,
            not_include_vat,
            discount_percentage,

        } = documentForm.getValues();

        return (
            <div className="summary">

                {(settings.display_items || settings.display_works) && (
                    <div className="summary-block">
                        <div className="summary-block-title">
                            <Typography variant="body1" component="span">
                                סיכום חשבונית:
                            </Typography>
                        </div>
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ כללי: {total}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                הנחה: <span dir="ltr">{discount}₪</span> <span dir="ltr">{"(" + discount_percentage + "%)"}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                סכום לפני מע"מ: {((include_vat || 0) + (not_include_vat || 0)).toFixed(2)}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                פטור ממס: {not_include_vat}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                חייב במס: {include_vat}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                17% מע"מ: {vat}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                לתשלום: {to_pay}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                תאריך פירעון:
                            </Typography>
                        </div>
                    </div>
                )}

                {settings.type === "קבלה" && (
                    <div className="summary-block">
                        <div className="summary-block-title">
                            <Typography variant="body1" component="span">
                                סיכום חשבוניות:
                            </Typography>
                        </div>
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {to_pay}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                ניכוי במקור:
                            </Typography>
                            <Typography variant="body1" component="div">
                                הנחה:
                            </Typography>
                            <Typography variant="body1" component="div">
                                לתשלום: {to_pay}₪
                            </Typography>
                        </div>
                    </div>
                )}

                {settings.type === "הפקדה" && (
                    <div className="summary-block">
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {deposit || 0}₪
                            </Typography>
                        </div>
                    </div>
                )}

                {settings.type === "הוצאה מהקופה" && (
                    <div className="summary-block">
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {to_pay}₪
                            </Typography>
                        </div>
                    </div>
                )}

                {(settings.type === "חשבונית מס קבלה" || settings.type === "קבלה") && (
                    <div className="summary-block">
                        <div className="summary-block-title">
                            <Typography variant="body1" component="span">
                                משולם:
                            </Typography>
                        </div>
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {(receipt || 0)}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                יתרה: {balance || 0}₪
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    else if (method === "edit") {

        documentForm = documentForm as UseFormReturn<DocumentEdit>;

        const {

            total,
            discount,
            vat,
            to_pay,
            balance,
            receipt,
            deposit,
            include_vat,
            not_include_vat,
            discount_percentage,

        } = documentForm.getValues();


        return (
            <div className="summary">

                {(settings.display_items || settings.display_works) && (
                    <div className="summary-block">
                        <div className="summary-block-title">
                            <Typography variant="body1" component="span">
                                סיכום חשבונית:
                            </Typography>
                        </div>
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ כללי: {total}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                הנחה: <span dir="ltr">{discount || 0}₪</span> <span dir="ltr">{"(" + discount_percentage + "%)"}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                סכום לפני מע"מ: {((include_vat || 0) + (not_include_vat || 0)).toFixed(2)}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                פטור ממס: {not_include_vat}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                חייב במס: {include_vat}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                17% מע"מ: {vat}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                לתשלום: {to_pay}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                תאריך פירעון:
                            </Typography>
                        </div>
                    </div>
                )}

                {settings.type === "קבלה" && (
                    <div className="summary-block">
                        <div className="summary-block-title">
                            <Typography variant="body1" component="span">
                                סיכום חשבוניות:
                            </Typography>
                        </div>
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {to_pay}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                ניכוי במקור:
                            </Typography>
                            <Typography variant="body1" component="div">
                                הנחה:
                            </Typography>
                            <Typography variant="body1" component="div">
                                לתשלום: {to_pay}₪
                            </Typography>
                        </div>
                    </div>
                )}

                {settings.type === "הפקדה" && (
                    <div className="summary-block">
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {deposit}₪
                            </Typography>
                        </div>
                    </div>
                )}

                {settings.type === "הוצאה מהקופה" && (
                    <div className="summary-block">
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {to_pay}₪
                            </Typography>
                        </div>
                    </div>
                )}

                {(settings.type === "חשבונית מס קבלה" || settings.type === "קבלה") && (
                    <div className="summary-block">
                        <div className="summary-block-title">
                            <Typography variant="body1" component="span">
                                משולם:
                            </Typography>
                        </div>
                        <div className="summary-block-details">
                            <Typography variant="body1" component="div">
                                סה"כ: {receipt}₪
                            </Typography>
                            <Typography variant="body1" component="div">
                                יתרה: {balance}₪
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        )
    }

}

export default Summary