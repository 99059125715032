import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentItemCustomDataSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    document_item_id: z.number(),
    field_index: z.number(),

    created_at: z.string(),
    updated_at: z.string(),

    // nullable
    field_value: z.string().nullish(),


});

export const DocumentItemCustomDataFormSchema = z.object({

    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),
    document_item_id: z.number().optional(),

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    // required
    field_index: z.number(),

    // optional nullable fields
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    
});

export type DocumentItemCustomData = z.infer<typeof DocumentItemCustomDataSchema>
export type DocumentItemCustomDataForm = z.infer<typeof DocumentItemCustomDataFormSchema>