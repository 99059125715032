import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledSelectInvoices } from "./SelectDeposits.styled"
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from "react";
import Paper from '@mui/material/Paper';
import { z } from "zod";
import { getDocumentPayments } from "../../../../services/documentPaymentService";
import { DocumentDepositAdd } from "../../../../models/document-deposit";
import { Payment, PaymentSchema } from "../../../../models/payment";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../../services/errorHandling";
import { dateStringFormatPrinter } from "../../../../helpers/helpers";
import dayjs from "dayjs";
import { Account } from "../../../../models/account";

interface DialogProps {
    depositType: "הפקדה רגילה" | "הפקדה דחויה" | "הפקדת מזומן" | "נכיון שיקים";
    kupaAccount: Account;
    open: boolean;
    onClose: (selectedDesposits?: DocumentDepositAdd[], despoditSum?: number) => void;

}


function SelectDeposits({ depositType, kupaAccount, open, onClose }: DialogProps) {

    const { showBoundary } = useErrorBoundary();

    const [payments, setPayments] = useState<Payment[]>();
    const [paymentsToSelect, setPaymentsToSelect] = useState<Payment[]>();
    const [selectedDesposits, setSelectedDesposits] = useState<DocumentDepositAdd[]>([]); // Track selected documents
    const [sum, setSum] = useState<number>();

    const abortControllerRef = useRef<AbortController | null>(null);

    // fetch the payments in the first time and filter the list to select by the deposit type and kupa account
    useEffect(() => {

        if (open && !payments) {
            fethDocumentsPayments();
        }

    }, [open]);


    useEffect(() => {

        if (payments) {
            setSelectedDesposits([]);
            setDepositsToSelect(payments, depositType, kupaAccount);
        }


    }, [payments, depositType, kupaAccount]);



    //update sum after select invoices changed
    useEffect(() => {
        if (selectedDesposits) {

            let sum = 0;

            selectedDesposits.forEach(documentPayment => {
                sum += documentPayment.total;
            });

            setSum(sum);
        }

    }, [selectedDesposits]);






    const fethDocumentsPayments = async () => {

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        const body = {
            // kupa_account: {
            //     payment_type: "שיקים"
            // }
        }


        try {
            const payments: Payment[] = await getDocumentPayments('id', "0", "ASC", ["document.customer", "document.documentSettings", "kupaAccount"], body, abortControllerRef.current.signal);

            const parsePayments: Payment[] = z.array(PaymentSchema).parse(payments);

            console.log("parsePayments", parsePayments);

            setPayments(parsePayments);
            // setDocuments(documents);
        } catch (error) {
            handleApiErrors(error, showBoundary);;
        }
    }

    function setDepositsToSelect(payments: Payment[], depositType: string, kupaAccount: Account): void {

        if (depositType === "הפקדה רגילה") {

            setPaymentsToSelect(payments.filter(payment => {

                const isPaymentTypeEqual: boolean = payment.kupa_account?.payment_type === kupaAccount.payment_type;
                const isDateTodayIsSameOrAfter: boolean = payment.to_date!== null && (dayjs().isSame(dayjs(payment.to_date)) || dayjs().isAfter(dayjs(payment.to_date)));
                const isPaymentPlaceIsKupa: boolean = payment.placed === "קופה";

                return isPaymentTypeEqual && isPaymentPlaceIsKupa && isDateTodayIsSameOrAfter? true : false;
                    
            }));
        }

        if (depositType === "הפקדה דחויה" || depositType === "נכיון שיקים") {
            
            setPaymentsToSelect(payments.filter(payment => {

                const isPaymentTypeEqual: boolean = payment.kupa_account?.payment_type === kupaAccount.payment_type;
                const isPaymentPlaceIsKupa: boolean = payment.placed === "קופה";
                const isDateTodayIsBefore: boolean = payment.to_date!== null && dayjs().isBefore(dayjs(payment.to_date));

                return isPaymentTypeEqual && isPaymentPlaceIsKupa && isDateTodayIsBefore? true : false;
            }));
        }
    }


    const handleCheckboxChange = (payment: Payment) => {

        const deposit: DocumentDepositAdd = { ...payment, payment_id: payment.id, deposit_type: depositType }

        setSelectedDesposits((prevSelected) => {

            // Check if the deposit with the same payment_id is already selected
            const isAlreadySelected = prevSelected.some(
                (doc) => doc.payment_id === deposit.payment_id
            );

            // Toggle selection
            if (isAlreadySelected) {
                // Remove from selection if already selected
                return prevSelected.filter((doc) => doc.payment_id !== deposit.payment_id);
            } else {
                // Add to selection if not already selected
                return [...prevSelected, deposit];
            }
        });
    };


    function handleClose() {
        onClose();
    };

    function handleConfirmButton() {
        onClose(selectedDesposits, sum);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <StyledSelectInvoices>
                {payments && (
                    <Box>
                        <h2>בחירת {kupaAccount.payment_type}</h2>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 850 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right">קופה</TableCell>
                                        <TableCell align="right">שם חשבון</TableCell>
                                        <TableCell align="right">בנק</TableCell>
                                        <TableCell align="right">מספר שיק</TableCell>
                                        <TableCell align="right">תאריך</TableCell>
                                        <TableCell align="right">סכום</TableCell>
                                        <TableCell align="right">מסמך</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paymentsToSelect?.map((payment, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <input
                                                    type="checkBox"
                                                    checked={selectedDesposits.some((doc) => doc.payment_id === payment.id)}
                                                    onChange={() => handleCheckboxChange(payment)}
                                                />
                                            </TableCell>
                                            <TableCell>{payment.kupa_account?.account_name}</TableCell>
                                            <TableCell>{payment.document?.customer?.account_name}</TableCell>
                                            <TableCell>{payment.bank_name}</TableCell>
                                            <TableCell>{payment.check_number}</TableCell>
                                            <TableCell>{dateStringFormatPrinter(payment.to_date)}</TableCell>
                                            <TableCell>{payment.total}</TableCell>
                                            <TableCell>{payment.document?.document_settings?.short_name} {payment.document?.document_number}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className="details">
                            <span>סכום שיקים שנבחרו: {sum}</span>
                        </div>
                        <div className="buttons">
                            <Button onClick={handleConfirmButton}>בחירה</Button>
                        </div>

                    </Box>
                )}


            </StyledSelectInvoices>
        </Dialog>

    )
}

export default SelectDeposits