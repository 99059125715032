import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentCustomerCustomDataSchema = z.object({

    // required
    id: z.number(), // id.
    business_id: z.number(), // business object id.
    document_customer_id: z.number(), // document-customer object id.
    field_index: z.number(), // field index.

    created_at: z.string(),
    updated_at: z.string(),


    // nullable
    field_value: z.string().nullable(), // field value.


});

export const DocumentCustomerCustomDataFormSchema = z.object({

    // optional in create
    id: z.number().optional(), // id.
    business_id: z.number().optional(), // business object id.
    document_customer_id: z.number().optional(), // document-customer object id.

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    // required
    field_index: z.number(), // field index.

    // optional nullable fields
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // field value.

});

export type DocumentCustomerCustomData = z.infer<typeof DocumentCustomerCustomDataSchema>
export type DocumentCustomerCustomDataForm = z.infer<typeof DocumentCustomerCustomDataFormSchema>