import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);

export const CarCustomDataSchema = z.object({
  
    // required
    id: z.number(), // id.
    business_id: z.number(), // business object id.
    car_id: z.number(), // car object id.
    field_index: z.number(), // field index.

    created_at: z.string(),
    updated_at: z.string(),
    
    // optional
    field_value: z.string().nullable(),

});

export const CarCustomDataFormSchema = z.object({
    
    // optional in create
    id: z.number().optional(), // id.
    business_id: z.number().optional(), // business object id.
    car_id: z.number().optional(), // car object id.

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    // required
    field_index: z.number(), // field index.

    // optional nullable fields
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullable()),

});

export type CarCustomData = z.infer<typeof CarCustomDataSchema>
export type CarCustomDataForm = z.infer<typeof CarCustomDataSchema>


