import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledSelectInvoices } from "./SelectInvoices.styled"
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from "react";
import { getOpenDocuments } from "../../../../services/documentsService";
import PrintFieldValue from "../../../../components/print-field-value/PrintFieldValue";
import Paper from '@mui/material/Paper';
import { Document, DocumentSchema } from "../../../../models/document";
import { z } from "zod";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../../services/errorHandling";
import { dateStringFormatPrinter } from "../../../../helpers/helpers";


interface DialogProps {
    open: boolean;
    onClose: (selectedDocuments?: Document[]) => void;
    accountId?: number;
}

const manufacturerField = {
    field_name: "car.manufacturer",
    field_type: 4,
    field_table: 1,
}

const modelField = {
    field_name: "car.model",
    field_type: 6,
    field_table: 0,
}

function SelectInvoices({ onClose, open, accountId }: DialogProps) {

    const { showBoundary } = useErrorBoundary();

    const [documents, setDocuments] = useState<Document[]>();
    const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]); // Track selected documents
    const [sum, setSum] = useState<number>();

    const abortControllerRef = useRef<AbortController | null>(null);

    const handleCheckboxChange = (document: Document) => {
        setSelectedDocuments((prevSelected) => {
            // Toggle selection
            if (prevSelected.includes(document)) {
                // Remove from selection if already selected
                return prevSelected.filter((doc) => doc.id !== document.id);
            } else {
                // Add to selection if not already selected
                return [...prevSelected, document];
            }
        });
    };


    function handleClose() {
        onClose();
    };

    function handleConfirmButton() {
        onClose(selectedDocuments);
    };

    useEffect(() => {

        const fethDocuments = async () => {

            if(accountId){
                abortControllerRef.current?.abort();
                abortControllerRef.current = new AbortController();
    
                try {
                    const documents = await getOpenDocuments(accountId, abortControllerRef.current.signal);
                    const parseDocuments = z.array(DocumentSchema).parse(documents);
                    setDocuments(parseDocuments);
                } catch (error) {
                    handleApiErrors(error, showBoundary);;
                }
            }
        }

        if(open){
            fethDocuments();
        }
    }, [open]);

    //update details after select invoices changed
    useEffect(() => {
        if (selectedDocuments) {

            let sum = 0;

            selectedDocuments.forEach(document => {
                if(document.balance){
                    sum +=  document.balance;
                }
            });

            setSum(sum);
        }

    }, [selectedDocuments]);


    return (
        <Dialog onClose={handleClose} open={open}>
            <StyledSelectInvoices>
                {documents && (
                    <Box>
                        <h2>בחירת חשבוניות</h2>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 850 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right">מסמך</TableCell>
                                        <TableCell align="right">יתרה</TableCell>
                                        <TableCell align="right">סכום</TableCell>
                                        <TableCell align="right">תאריך</TableCell>
                                        <TableCell align="right">שם</TableCell>
                                        <TableCell align="right">מס. רישוי</TableCell>
                                        <TableCell align="right">יצרן</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documents?.map((document, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <input
                                                    type="checkBox"
                                                    checked={selectedDocuments.includes(document)}
                                                    onChange={() => handleCheckboxChange(document)}
                                                />
                                            </TableCell>
                                            <TableCell>{document.document_settings?.name} {document.document_number}</TableCell>
                                            <TableCell>{document.balance}₪</TableCell>
                                            <TableCell>{document.to_pay}₪</TableCell>
                                            <TableCell>{dateStringFormatPrinter(document.created_at)}</TableCell>
                                            <TableCell>{document.customer?.account_name}</TableCell>
                                            <TableCell>{document.car?.license_number}</TableCell>
                                            <TableCell><PrintFieldValue field={manufacturerField} accountData={document} /> <PrintFieldValue field={modelField} accountData={document} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                        <div className="details">
                            <span>סכום חשבוניות שנבחרו: {sum}</span>
                        </div>
                        <div className="buttons">
                            <Button onClick={handleConfirmButton}>בחירה</Button>
                        </div>

                    </Box>
                )}


            </StyledSelectInvoices>
        </Dialog>

    )
}

export default SelectInvoices