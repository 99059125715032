import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);

export const WorkCustomDataSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    work_id: z.number(),
    field_index: z.number(),


    created_at: z.string(),
    updated_at: z.string(),


    // nullable
    field_value: z.string().nullable(),

});

export const WorkCustomDataFormSchema = z.object({
    
    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),
    work_id: z.number().optional(),

    created_at: z.string().optional(),
    updated_at: z.string().optional(),
    
    // required
    field_index: z.number(),

    // optional nullable fields
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullable()),

});

export type WorkCustomData = z.infer<typeof WorkCustomDataSchema>
export type WorkCustomDataForm = z.infer<typeof WorkCustomDataFormSchema>
