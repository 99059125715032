import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import dayjs from "dayjs";


z.setErrorMap(customErrorMap);

export const DocumentPaymentSchema = z.object({

    //from server (optional)
    id: z.number(),
    business_id: z.number(),
    created_at: z.string(),
    updated_at: z.string(),
    document_id: z.number(),

    //inputs required
    total: z.number(),
    kupa_account_id: z.number(),
    // account_id: z.number(),

    //inputs optional
    bank_account_id: z.number().nullable(),
    bank_account_number: z.string().nullable(),
    bank_branch_number: z.string().nullable(),
    bank_name: z.string().nullable(),
    bank_number: z.string().nullable(),
    check_number: z.string().nullable(),
    to_date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullable()),
    tax: z.string().nullable(),
    placed: z.string(),

});


export const DocumentPaymentFormSchema = z.object({

    //from server (optional)
    document_id: z.number().optional(),

    //inputs required
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().gt(0)),
    kupa_account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),

    //inputs optional
    // account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    bank_account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    bank_account_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^\d+$/, { message: "יכול להכיל ספרות בלבד." }).nullish()),
    bank_branch_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^\d+$/, { message: "יכול להכיל ספרות בלבד." }).nullish()),
    bank_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    bank_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^\d+$/, { message: "יכול להכיל ספרות בלבד." }).nullish()),
    check_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^\d+$/, { message: "יכול להכיל ספרות בלבד." }).nullish()),
    to_date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD'), 'to_date'), z.string().nullish()),
    tax: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

    payment_method: z.string().nullish(),

})// Refine each field individually when payment method is 'שיקים'
    .refine(data => {
        if (data.payment_method === 'שיקים') {
            return data.bank_account_number;
        }
        return true;
    }, {
        message: "שדה חובה",
        path: ["bank_account_number"], // Error for bank_account_number
    })
    .refine(data => {
        if (data.payment_method === 'שיקים') {
            return data.bank_branch_number;
        }
        return true;
    }, {
        message: "שדה חובה",
        path: ["bank_branch_number"], // Error for bank_branch_number
    })
    .refine(data => {
        if (data.payment_method === 'שיקים') {
            return data.bank_name;
        }
        return true;
    }, {
        message: "שדה חובה",
        path: ["bank_name"], // Error for bank_name
    })
    .refine(data => {
        if (data.payment_method === 'שיקים') {
            return data.bank_number;
        }
        return true;
    }, {
        message: "שדה חובה",
        path: ["bank_number"], // Error for bank_number
    })
    .refine(data => {
        if (data.payment_method === 'שיקים') {
            return data.check_number;
        }
        return true;
    }, {
        message: "שדה חובה",
        path: ["check_number"], // Error for check_number
    })
    .refine(data => {
        if (data.payment_method === 'העברות') {
            return data.bank_account_id;
        }
        return true;
    }, {
        message: "שדה חובה",
        path: ["bank_account_id"], // Error for bank_account_id
    });




export type DocumentPayment = z.infer<typeof DocumentPaymentSchema>
export type DocumentPaymentForm = z.infer<typeof DocumentPaymentFormSchema>
