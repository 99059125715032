import { z } from "zod";
import { DocumentSettingsSchema, DocumentSettingsSearchSchema } from "./document-settings";
import { AccountSchema, AccountSearchSchema } from "./account";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentItemAddSchema, DocumentItemEditSchema, DocumentItemSchema } from "./document-item";
import { DocumentWorkAddSchema, DocumentWorkEditSchema, DocumentWorkSchema } from "./document-work";
import { CarSearchSchema } from "./car";
import dayjs from "dayjs";
import { DocumentCarAddSchema, DocumentCarEditSchema, DocumentCarSchema } from "./document-car";
import { DocumentCustomerAddSchema, DocumentCustomerEditSchema, DocumentCustomerSchema } from "./document-customer";
import { DocumentPaymentFormSchema, DocumentPaymentSchema } from "./document-payment";
import { EntrySchema } from "./entry";
import { DocumentClosingFormSchema, DocumentClosingSchema } from "./document-closing";
import { DocumentDepositAddSchema, DocumentDepositSchema } from "./document-deposit";

z.setErrorMap(customErrorMap);

// const OptionalCarSchema = CarSchema.nullable();


export const DocumentSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    document_settings_id: z.number(),
    account_id: z.number(),
    document_number: z.number(),
    date_time: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD HH:mm')), z.string()),
    status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
    

    created_at: z.string(),
    updated_at: z.string(),

    // nullable
    total: z.number().nullable(),
    include_vat: z.number().nullable(),
    not_include_vat: z.number().nullable(),
    vat: z.number().nullable(),
    to_pay: z.number().nullable(),
    balance: z.number().nullable(),
    discount: z.number().nullable(),
    discount_percentage: z.number().nullable(),
    reference_id: z.number().nullable(),
    works_total: z.number().nullable(),
    items_total: z.number().nullable(),
    reference: z.string().nullable(),
    receipt: z.number().nullable(),
    deposit: z.number().nullable(),


    // loads
    document_settings: DocumentSettingsSchema.nullish(),
    account: AccountSchema.nullish(),
    customer: DocumentCustomerSchema.nullish(),
    car: DocumentCarSchema.nullish(),
    items: z.array(DocumentItemSchema).nullish(),
    works: z.array(DocumentWorkSchema).nullish(),
    payments: z.array(DocumentPaymentSchema).nullish(),
    entrys: z.array(EntrySchema).nullish(),
    documents_closing: z.array(DocumentClosingSchema).nullish(),
    deposits: z.array(DocumentDepositSchema).nullish(),
    closing: z.array(DocumentClosingSchema).nullish(),

})


export const DocumentEditSchema = z.object({

    // required
    id: z.number(),
    account_id: z.number(),
    document_number: z.number(),
    status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
    

    // optional and nullable
    reference_id: z.number().nullish(),
    works_total: z.number().nullish(),
    items_total: z.number().nullish(),
    reference: z.string().nullish(),
    receipt: z.number().nullish(),
    deposit: z.number().nullish(),

    total: z.number().gte(0).nullish(),
    include_vat: z.number().nullish(),
    not_include_vat: z.number().nullish(),
    vat: z.number().nullish(),
    to_pay: z.number().gte(0).nullish(),
    balance: z.number().nullish(),
    discount: z.number().nullish(),
    discount_percentage: z.number().nullish(),

    // required fields
    document_settings_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    date_time: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD HH:mm')), z.string()),


    //loads
    document_settings: DocumentSettingsSchema.nullish(),
    customer: DocumentCustomerEditSchema.nullish(),
    car: DocumentCarEditSchema.nullish(),
    items: z.array(DocumentItemEditSchema).nullish(),
    works: z.array(DocumentWorkEditSchema).nullish(),
    payments: z.array(DocumentPaymentFormSchema).nullish(),
    documents_closing: z.array(DocumentClosingFormSchema).nullish(),
    deposits: z.array(DocumentDepositAddSchema).nullish(),

})

export const DocumentAddSchema = z.object({

    // required
    account_id: z.number(),
    document_number: z.number(),
    status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
    

    // optional and nullable
    total: z.number().gte(0).nullish(),
    include_vat: z.number().nullish(),
    not_include_vat: z.number().nullish(),
    vat: z.number().nullish(),
    to_pay: z.number().gte(0).nullish(),
    balance: z.number().nullish(),
    reference_id: z.number().nullish(),
    works_total: z.number().nullish(),
    items_total: z.number().nullish(),
    reference: z.string().nullish(),
    receipt: z.number().nullish(),
    deposit: z.number().nullish(),
    discount: z.number().nullish(),
    discount_percentage: z.number().nullish(),

    // required fields
    document_settings_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    date_time: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD HH:mm')), z.string()),


    //loads
    document_settings: DocumentSettingsSchema.nullish(),
    customer: DocumentCustomerAddSchema.nullish(),
    car: DocumentCarAddSchema.nullish(),
    items: z.array(DocumentItemAddSchema).nullish(),
    works: z.array(DocumentWorkAddSchema).nullish(),
    payments: z.array(DocumentPaymentFormSchema).nullish(),
    documents_closing: z.array(DocumentClosingFormSchema).nullish(),
    deposits: z.array(DocumentDepositAddSchema).nullish(),

})

// export const DocumentFormSchema = z.object({

//     // optional in create
//     id: z.number().optional(),
//     business_id: z.number().optional(),

//     created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
//     updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


//     // required
//     account_id: z.number(),
//     document_number: z.number(),
//     status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
//     total: z.number().gte(0),
//     vat: z.number(),
//     to_pay: z.number().gte(0),
//     balance: z.number(),

//     // optional and nullable
//     reference_id: z.number().nullish(),
//     works_total: z.number().nullish(),
//     items_total: z.number().nullish(),
//     reference: z.string().nullish(),


//     // required fields
//     document_settings_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
//     date_time: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD HH:MM')), z.string()),

    
//     // client side
//     deposit_Type: z.preprocess((val) => myPreProcess(val), z.enum(["הפקדה רגילה", "הפקדה דחויה", "הפקדת מזומן", "נכיון שיקים"]).optional()),
//     include_vat: z.number().nullish(),
//     not_include_vat: z.number().nullish(),
//     discount: z.number().nullish(),
//     discount_percentage: z.number().nullish(),
//     total_payed: z.number().nullish(),

//     //loads
//     document_settings: DocumentSettingsSchema.nullish(),
//     customer: DocumentCustomerFormSchema.nullish(),
//     car: DocumentCarAddSchema.nullish(),
//     items: z.array(DocumentItemFormSchema).nullish(),
//     works: z.array(DocumentWorkFormSchema).nullish(),
//     payments: z.array(DocumentPaymentFormSchema).nullish(),
//     documents_closing: z.array(DocumentClosingFormSchema).nullish(),

// })


export const DocumentSearchSchema = z.object({


    account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    document_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    date_time: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullish()),
    status: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    vat: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    to_pay: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    balance: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    works_total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    items_total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    reference: z.preprocess((val) => myPreProcess(val), z.string().nullish()),


    // loads
    document_settings: DocumentSettingsSearchSchema.optional(),
    account: AccountSearchSchema.optional(),
    customer: AccountSearchSchema.optional(),
    car: CarSearchSchema.optional(),

});

export type Document = z.infer<typeof DocumentSchema>
export type DocumentEdit = z.infer<typeof DocumentEditSchema>
export type DocumentAdd = z.infer<typeof DocumentAddSchema>
// export type DocumentForm = z.infer<typeof DocumentFormSchema>
export type DocumentSearch = z.infer<typeof DocumentSearchSchema>
