import axiosClient from "../axios-client";
import { DocumentPayment } from "../models/document-payment";


export const getDocumentPayments = async (sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', load: string[], body: object = {}, signal: AbortSignal): Promise<DocumentPayment[]> => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.post(`/api/documents-payments/search/${maxRecords}/${sortKey}/${sortMethod}${loadString}`, body, {
        signal: signal // Pass the signal to the request options
    });
    return response.data;
}