import { z } from "zod";
import { customErrorMap } from "../services/zodService";
import { EntryDocumentSchema } from "./entry-document";

z.setErrorMap(customErrorMap);



export const EntrySchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    account_id: z.number(),
    document_id: z.number(),
    entry_name: z.string(),
    info: z.string(),

    created_at: z.string(),
    updated_at: z.string(),


    // nullable
    debit: z.number().nullable(),
    credit: z.number().nullable(),
    balance: z.number().optional(),
    counter_account: z.string().nullable(),
    doc_name_2: z.string().nullable(),
    doc_num_2: z.number().nullable(),
    ref_2: z.string().nullable(),
    value: z.string().nullable(),
      

    // loads
    document: EntryDocumentSchema.optional(),

});


export type Entry = z.infer<typeof EntrySchema>
