import { z } from "zod";
import { customErrorMap, myPreProcess } from './../services/zodService';
import { ItemCustomDataFormSchema, ItemCustomDataSchema } from "./item-custom-data";
import { ItemEntrySchema } from "./item-entry";
// import { ItemCustomData } from "./item-custom-data";

z.setErrorMap(customErrorMap);


export const ItemSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    sku: z.string(),

    created_at: z.string(),
    updated_at: z.string(),

        
    // nullable
    name: z.string().nullable(),
    info: z.string().nullable(),
    group: z.number().nullable(),
    manufacturer: z.number().nullable(),
    model: z.number().nullable(),
    price: z.number().nullable(),
    cost: z.number().nullable(),
    income_card: z.number().nullable(),
    outcome_card: z.number().nullable(),

    
    // loads
    entries: z.array(ItemEntrySchema).nullish(),
    custom_data: z.array(ItemCustomDataSchema).nullish(),
});

export const ItemEditSchema = z.object({
        
    // optional nullable fields
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    group: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    cost: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
    // loads
    entries: z.array(ItemEntrySchema).nullish(),
    custom_data: z.array(ItemCustomDataFormSchema).nullish(),
});

export const ItemAddSchema = z.object({
    
    // required fields
    sku: z.string().min(1),
        
    // optional nullable fields
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    group: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    cost: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
    // loads
    entries: z.array(ItemEntrySchema).nullish(),
    custom_data: z.array(ItemCustomDataFormSchema).nullish(),
});

export const ItemFormSchema = z.object({

    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    // required fields
    sku: z.string().min(1),
        
    // optional nullable fields
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    group: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    cost: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
    // loads
    entries: z.array(ItemEntrySchema).nullish(),
    custom_data: z.array(ItemCustomDataFormSchema).nullish(),
});

export const ItemSearchSchema = z.object({

    //inputs required
    sku: z.string().nullish(),
        
    //inputs optional
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    group: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    cost: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    entries: z.preprocess((val) => myPreProcess(val), z.array(ItemEntrySchema).nullish()),

    custom_data: z.preprocess((val) => myPreProcess(val), z.array(ItemCustomDataSchema).optional()),
});

export type Item = z.infer<typeof ItemSchema>
export type ItemEdit = z.infer<typeof ItemEditSchema>
export type ItemAdd = z.infer<typeof ItemAddSchema>
export type ItemForm = z.infer<typeof ItemFormSchema>
export type ItemSearch = z.infer<typeof ItemSearchSchema>