import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentItemCustomDataFormSchema, DocumentItemCustomDataSchema } from "./document-item-custom-data";

z.setErrorMap(customErrorMap);

export const DocumentItemSchema = z.object({

    // required
    id: z.number(), // id.
    business_id: z.number(), // business object id.
    document_id: z.number(), // document object id.
    amount: z.number(), // amount of items.
    price: z.number(), // price of one item.
    total: z.number(), // total price.

    created_at: z.string(),
    updated_at: z.string(),

    // nullable
    item_id: z.number().nullable(), // original item object id.
    sku: z.string().nullable(), // sku.
    name: z.string().nullable(), // item name.
    discount: z.number().nullable(), // discount on price.
    income_card: z.number().nullable(), // income card.
    outcome_card: z.number().nullable(), // outcome card.
    
    //loads
    custom_data: z.array(DocumentItemCustomDataSchema).nullish(),

});

export const DocumentItemEditSchema = z.object({

    // optional in create
    id: z.number().optional(), // id.
    

    // required fields
    amount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)), // amount of items.
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // price of one item.
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // total price.
    

    // optional nullable fields
    item_id: z.number().nullish(), // original item object id.
    sku: z.string().optional().nullish(), // sku.
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // item name.
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(0).max(100).nullish()), // discount on price.
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // neto
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // income card.
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // outcome card.
    
    // loads
    custom_data: z.array(DocumentItemCustomDataFormSchema).nullish(),

});

export const DocumentItemAddSchema = z.object({

    // optional in create
    id: z.number().optional(), // id.
    

    // required fields
    amount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)), // amount of items.
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // price of one item.
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // total price.
    

    // optional nullable fields
    item_id: z.number().nullish(), // original item object id.
    sku: z.string().optional().nullish(), // sku.
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // item name.
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(0).max(100).nullish()), // discount on price.
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // neto
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // income card.
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // outcome card.
    
    // loads
    custom_data: z.array(DocumentItemCustomDataFormSchema).nullish(),

});

export type DocumentItem = z.infer<typeof DocumentItemSchema>
export type DocumentItemEdit = z.infer<typeof DocumentItemEditSchema>
export type DocumentItemAdd = z.infer<typeof DocumentItemAddSchema>