import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentWorkCustomDataFormSchema, DocumentWorkCustomDataSchema } from "./document-work-custom-data";

z.setErrorMap(customErrorMap);

export const DocumentWorkSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    document_id: z.number(),
    hours: z.number(),
    price: z.number(),
    total: z.number(),

    created_at: z.string(),
    updated_at: z.string(),


    // nullable
    work_id: z.number().nullable(),
    work_code: z.string().nullable(),
    name: z.string().nullable(),
    income_card: z.number().nullable(),
    discount: z.number().nullable(),

    //loads
    custom_data: z.array(DocumentWorkCustomDataSchema).nullish(),

});

export const DocumentWorkEditSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    document_id: z.number().optional(),

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    //inputs required
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    
    //inputs optional
    work_id: z.number().nullish(),
    work_code: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    //loads
    custom_data: z.array(DocumentWorkCustomDataFormSchema).nullish(),

});

export const DocumentWorkAddSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    document_id: z.number().optional(),

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    //inputs required
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    
    //inputs optional
    work_id: z.number().nullish(),
    work_code: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    //loads
    custom_data: z.array(DocumentWorkCustomDataFormSchema).nullish(),

});

export type DocumentWork = z.infer<typeof DocumentWorkSchema>
export type DocumentWorkEdit = z.infer<typeof DocumentWorkEditSchema>
export type DocumentWorkAdd = z.infer<typeof DocumentWorkAddSchema>