import { ErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import { StyledErrorPage } from "./error-page.styled";
import axios from "axios";
import { useEffect } from "react";
// import { useAuth } from "../../utils/AuthContext";


export default function ErrorPage() {
  const error: unknown = useRouteError();
  console.error("ErrorPage: ", error);

  const navigate = useNavigate();
  // const { logoutUser } = useAuth();




  const errorMessage = setMessage(error);


  function setMessage(error: unknown): string {

    console.error("ErrorPage", error);

    if (axios.isAxiosError(error)) {

      // the token not exist: user not logged in.
      if (error.response?.data.error === "Resource not found.") {
        return "לא נמצא.";
      }

      // the token not exist: user not logged in.
      if (error.response?.data.error === "Unauthorized") {
        return "אין לך הרשאה מתאימה לגשת למשאב זה.";
      }
    }

    return "מצטערים. התרחשה שגיאה.";
  }

  useEffect(() => {
    if (axios.isAxiosError(error)) {

      // console.log("axios error:", error);

      // the token not exist: user not logged in.
      if (error.response?.data.message === "Unauthenticated.") {
        console.log("if: Unauthenticated.");
        // logoutUser();
        navigate("/login");
      }
    }
  }, []);


  return (
    <StyledErrorPage>
      <h1>שגיאה!</h1>
      <p>
        סטטוס קוד: <i>{(error as ErrorResponse).status}</i>
      </p>
      <p>
        הודעת שגיאה: <i>{(error as ErrorResponse).statusText || (error as Error).message}</i>
      </p>
      <p>
        הודעה מותאמת אישית: <i>{errorMessage}</i>
      </p>
      <button onClick={() => { navigate("/") }}>חזור למסך הראשי</button>
    </StyledErrorPage>
  );
}