
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { CanceledError } from 'axios';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import { RootState } from '../../redux/store';
import { Document, DocumentSearch, DocumentSearchSchema } from '../../models/document';
import { DocumentStructure } from '../../models/document-structure';
import { getDocumentsStructure } from '../../services/documentsStructureService';
import { setDocumentsSearch } from '../../redux/features/browse';
import { getDocuments } from '../../services/documentsService';
import { deleteEmptyFieldsFromObject, setAllValuesToNull } from '../../helpers/helpers';
import { setDocumentsStructure } from '../../redux/features/structure';
import SearchTable from '../../components/search-table/SearchTable';
import DataTable from '../../components/data-table/DataTable';
import { StyledCustomers } from '../tickets/customers/customers.styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useTheme } from "@emotion/react";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from '../../services/errorHandling';

//state properties:

interface LocationState {

  from?: string;
  method?: string;
  document_settings_id?: number;
  searchFormData?: DocumentSearch;

}

function Documents() {

  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch()
  const { state } = useLocation() as { state: LocationState };
  const navigate = useNavigate();
  const theme = useTheme();



  const documentStructure = useSelector((state: RootState) => state.structure.document)
  const documentsSearch = useSelector((state: RootState) => state.browse.documentsSearch)

  const [data, setData] = useState<Document[]>([]);
  const [searchStructure, setSearchStructure] = useState<DocumentStructure[]>();
  const [tableStructure, setTableStructure] = useState<DocumentStructure[]>();
  const [sortKey, setSortKey] = useState<string>('id');
  const [sortMethod, setSortMethod] = useState<string>('DESC');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const documentSearchForm = useForm<DocumentSearch>({
    resolver: zodResolver(DocumentSearchSchema),
    defaultValues: {
      // customer: {
      //   account_number: 5
      // },
      ...documentsSearch,
    }
  });

  const options = [
    {
      label: "עריכה",
      icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><EditIcon fontSize="small" /></ListItemIcon>,
      href: ""
    },
  ];


  // get documents structure
  useEffect(() => {
    if (!documentStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);

  //set the structure fields in the search and in the table
  useEffect(() => {
    setSearchStructure(documentStructure?.filter(field => field.show_search))
    setTableStructure(documentStructure?.filter(field => field.show_browse))
  }, [documentStructure]);


  //search documents when user changes sort method
  useEffect(() => {
    if (sortKey && sortMethod) {
      documentSearchForm.handleSubmit(onSearch)();
    }
  }, [sortKey, sortMethod]);


  //search documents when one search form input value change
  useEffect(() => {
    documentSearchForm.watch(() => {

      documentSearchForm.handleSubmit(onSearch)();
    })
  }, [documentSearchForm.watch]);



  function handleSortKeyChanged(value: string) {
    setSortKey(value);
  }

  function handleSortMethodChanged(value: string) {
    setSortMethod(value);
  }


  const onSearch: SubmitHandler<DocumentSearch> = (data) => {

    dispatch(setDocumentsSearch(deleteEmptyFieldsFromObject(data)));
    getData(deleteEmptyFieldsFromObject(data));
  }


  async function getData(data: DocumentSearch) {

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    setIsLoading(true);

    if(state?.searchFormData){
      data = state.searchFormData;
    }


    try {
      const responseData = await getDocuments(sortKey || 'id', "10", sortMethod || 'ASC', ["documentSettings", "customer.customData", "car.customData", "works.customData", "items.customData", "payments", "account"], deleteEmptyFieldsFromObject(data), abortControllerRef.current.signal);
      setData(responseData || []);
      setIsLoading(false);
    } catch (error: unknown) {
      if (error instanceof CanceledError) {
        return;
      }
      handleApiErrors(error, showBoundary);
    }

  }

  
  async function getStructureAndSaveAtStore() {
    try {

      const structure = await getDocumentsStructure();

      dispatch(setDocumentsStructure(structure || []));

    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
  }

  function addDocument(): void {
    navigate(`${location.pathname}/add`);
  }

  function rowChosen<T>(row: T) {
    if (state?.from === "/documents/add") {

      if (state?.method === "browse document") {
        navigate(state?.from, {
          replace: true,
          state: {
            // ...location.state,
            method: 'browse document',
            document_settings_id: state?.document_settings_id,
            document: row,
            customer: null,
            car: null,
            works: null,
            items: null,
            reference: null,
          }
        });
      }

      if (state?.method === "reference") {
        navigate(state?.from, {
          replace: true,
          state: {
            // ...location.state,
            method: 'reference',
            document_settings_id: state?.document_settings_id,
            document: row,
            customer: null,
            car: null,
            works: null,
            items: null,
            reference_id: (row as Document).id,
            reference: `${(row as Document).document_settings?.name} ${(row as Document).document_number}`,
            searchFormData: null,
          }
        });
      }


    }
  }

  function editSearchStructure() {
    navigate('/structure', {
      replace: true, state: {
        from: location.pathname,
        fromType: 'search',
        structureName: "מסמך",
        structureType: 'document',
        documentType: 'מסמך'
      }
    });
  }

  function editBrowseStructure() {
    navigate('/structure', {
      replace: true, state: {
        from: location.pathname,
        fromType: 'browse',
        structureName: "מסמך",
        structureType: 'document',
        documentType: 'מסמך'
      }
    });
  }

  function reset() {

    const values = setAllValuesToNull(documentSearchForm.getValues());
    values.date_time = null;

    // for (const key in values) {
    //   if (values.hasOwnProperty(key)) {
    //     documentSearchForm.setValue(key as keyof DocumentSearch, values[key as keyof DocumentSearch]);
    //   }
    // }

    documentSearchForm.reset(values);
  }

  return (

    <StyledCustomers>

      <div>
        <Typography variant="h5" component="span">
          דפדוף במסמכים
        </Typography>

        {/* <button onClick={addDocument}>+ הוספת מסמך</button> */}
        <Tooltip title="הוספת מסמך">
          <IconButton onClick={addDocument} sx={{ color: theme.colors.main_color }}>
            <NoteAddIcon />
          </IconButton>
        </Tooltip>
      </div>


      {searchStructure ?
        <SearchTable structure={[...searchStructure]} setStructure={editSearchStructure} reset={reset} useForm={documentSearchForm} style={{ backgroundColor: "rgb(35, 48, 68)", padding: 5, marginButton: 20 }} /> :
        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: theme.colors.background_color }} />}
      {tableStructure ?
        <DataTable structure={[...tableStructure]} setStructure={editBrowseStructure} sortKey={sortKey} setSortKey={handleSortKeyChanged} sortMethod={sortMethod} setSortMethod={handleSortMethodChanged} data={data} isLoading={isLoading} options={options} selectRow={state?.from ? true : false} onSelectRow={rowChosen} /> :
        <Skeleton variant="rectangular" height={150} animation="wave" sx={{ bgcolor: theme.colors.background_color }} />}

      {/* <DevTool control={documentSearchForm.control} /> */}


    </StyledCustomers>
  );
}

export default Documents;