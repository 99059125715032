import { z } from "zod";
import { AccountSchema } from "./account";
import { DocumentSchema } from "./document";
import { myPreProcess } from "../services/zodService";
import dayjs from "dayjs";

export const DocumentDepositSchema  = z.object({

    //from server (optional)
    id: z.number(),
    business_id: z.number(),
    created_at: z.string(),
    updated_at: z.string(),
    document_id: z.number(),

    //inputs required
    deposit_type: z.enum(["הפקדה רגילה","הפקדה דחויה","הפקדת מזומן","נכיון שיקים"]),
    total: z.number(),
    kupa_account_id: z.number(),
    // account_id: z.number(),

    //inputs optional
    bank_account_id: z.number().nullable(),
    bank_account_number: z.string().nullable(),
    bank_branch_number: z.string().nullable(),
    bank_name: z.string().nullable(),
    bank_number: z.string().nullable(),
    check_number: z.string().nullable(),
    to_date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullable()),
    // tax: z.string().nullable(),

})

export const DocumentDepositAddSchema  = z.object({

    //from server (optional)
    document_id: z.number(),

    //inputs required
    deposit_type: z.enum(["הפקדה רגילה","הפקדה דחויה","הפקדת מזומן","נכיון שיקים"]),
    total: z.number(),
    kupa_account_id: z.number(),
    // account_id: z.number(),

    //inputs optional
    bank_account_id: z.number().nullable(),
    bank_account_number: z.string().nullable(),
    bank_branch_number: z.string().nullable(),
    bank_name: z.string().nullable(),
    bank_number: z.string().nullable(),
    check_number: z.string().nullable(),
    to_date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullable()),
    // tax: z.string().nullable(),

    // client side
    payment_id: z.number(),
    
    // loads
    document: z.lazy(() => DocumentSchema).optional(),
    // document: DocumentSchema.optional(),
    kupa_account: AccountSchema.optional(),

})

export type DocumentDeposit = z.infer<typeof DocumentDepositSchema>
export type DocumentDepositAdd = z.infer<typeof DocumentDepositAddSchema>