import { z } from "zod";
import { customErrorMap, myPreProcess } from './../services/zodService';

z.setErrorMap(customErrorMap);

export const AccountStructureSchema = z.object({

    // required
    id: z.number(), // id.
    business_id: z.number(), // business object id.
    account_type: z.string(), // the account type this field belongs.
    account_title: z.string(), // the category name of the field in the account form.
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // set the field in the form as required.
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // show the field in the form.
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // show the field in the search.
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // show the field column in the table.
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // enable editing in the form.
    field_name: z.string(), // field adress for useForm.
    field_heb_name: z.string(), // address name for fields-stucrure.
    field_display_name: z.preprocess((val) => myPreProcess(val), z.string()), // field display name.
    field_length: z.preprocess((val) => myPreProcess(val), z.number()), // field length.
    field_type: z.preprocess((val) => myPreProcess(val), z.number()), // field-type table-index.

    created_at: z.string(),
    updated_at: z.string(),


    // nullable
    field_table: z.number().nullable(), // field table table-index
    custom_field: z.number().nullable(), // custom field index
    location: z.number().nullable(), // location of the field in the form

});

export const AccountStructureFormSchema = z.object({


    // optional in create
    id: z.number().optional(), // id.
    business_id: z.number().optional(), // business object id.

    created_at: z.string().optional(),
    updated_at: z.string().optional(),

    // required
    field_heb_name: z.string(), // address name for fields-stucrure.
    account_type: z.string(), // the account type this field belongs.
    account_title: z.string(), // the category name of the field in the account form.
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // set the field in the form as required.
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()), // enable editing in the form.
    field_name: z.string().min(1), // field adress for useForm.
    custom_field: z.number().nullish(), // custom field index

    // required fields
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_display_name: z.preprocess((val) => myPreProcess(val), z.string()),
    field_length: z.preprocess((val) => myPreProcess(val), z.number()),
    field_type: z.preprocess((val) => myPreProcess(val), z.number()),

    // optional nullable fields
    field_table: z.preprocess((val) => myPreProcess(val), z.number().nullish()),
    location: z.preprocess((val) => myPreProcess(val), z.number().nullish()),

});

export type AccountStructure = z.infer<typeof AccountStructureSchema>
export type AccountStructureForm = z.infer<typeof AccountStructureFormSchema>

