
import { useEffect, useRef, useState } from 'react';
import DataTable from '../../../components/data-table/DataTable';
import { getAccountsStructure } from '../../../services/accountsStructureService';
import SearchTable from '../../../components/search-table/SearchTable';
import { StyledCars } from './cars.styled';
import { deleteEmptyFieldsFromObject, setAllValuesToNull } from '../../../helpers/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { setCarsStructure } from '../../../redux/features/structure';
import { AccountStructure } from '../../../models/accounts-structure';
import { RootState } from '../../../redux/store';
import { Skeleton } from '@mui/material';
import { CanceledError } from 'axios';
import HistoryIcon from '@mui/icons-material/History';
import ListItemIcon from '@mui/material/ListItemIcon';
// import MovingIcon from '@mui/icons-material/Moving';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import EditIcon from '@mui/icons-material/Edit';
import { setCarsSearch } from '../../../redux/features/browse';
import { Car, CarSchema, CarSearch } from '../../../models/car';
import { getCars } from '../../../services/carsService';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from "@emotion/react";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from '../../../services/errorHandling';


interface SearchForm {
    [key: string]: string; // Index signature allowing any string key with string values
}
interface CarsProps {
    accountTypeName: string;
    searchFormData?: SearchForm;
}

function Cars({ accountTypeName, searchFormData }: CarsProps) {

    const dispatch = useDispatch()
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const carsStructure = useSelector((state: RootState) => state.structure.cars)
    const carsSearch = useSelector((state: RootState) => state.browse.carsSearch)

    const [data, setData] = useState<Car[]>([]);
    const [searchStructure, setSearchStructure] = useState<AccountStructure[]>();
    const [tableStructure, setTableStructure] = useState<AccountStructure[]>();
    const [sortKey, setSortKey] = useState<string>('id');
    const [sortMethod, setSortMethod] = useState<string>('DESC');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    const options = [
        {
            label: "עריכה",
            icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><EditIcon fontSize="small" /></ListItemIcon>,
            href: ""
        },
        {
            label: "הסטוריה",
            icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><HistoryIcon fontSize="small" /></ListItemIcon>,
            href: "history"
        },
        // {
        //     label: "תנועות",
        //     icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><MovingIcon fontSize="small" /></ListItemIcon>,
        //     href: "journal-entries"
        // },
        {
            label: "מסמכים",
            icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><ContactPageIcon fontSize="small" /></ListItemIcon>,
            href: "documents"
        },
    ];

    const carSearchForm = useForm<CarSearch>({
        defaultValues: {
            ...carsSearch,
            ...searchFormData
        }
    });
    const carSearchValues = carSearchForm.watch();
    const carSearchValuesRef = useRef(carSearchValues);


    useEffect(() => {
        if (!carsStructure) {
            getStructureAndSaveAtStore();
        }
    }, []);

    useEffect(() => {
        setSearchStructure(carsStructure?.filter(field => field.show_search))
        setTableStructure(carsStructure?.filter(field => field.show_browse))
    }, [carsStructure]);



    useEffect(() => {
        if (sortKey && sortMethod) {
            carSearchForm.handleSubmit(onSearch)();
        }
    }, [sortKey, sortMethod]);


    useEffect(() => {
        if ((JSON.stringify(carSearchValues) !== JSON.stringify(carSearchValuesRef?.current)) && sortKey && sortMethod) {
            carSearchForm.handleSubmit(onSearch)();
            carSearchValuesRef.current = carSearchValues;
        }
    }, [carSearchValues]);



    function handleSortKeyChanged(value: string) {
        setSortKey(value);
    }

    function handleSortMethodChanged(value: string) {
        setSortMethod(value);
    }

    const onSearch: SubmitHandler<CarSearch> = (data) => {
        dispatch(setCarsSearch(data));
        getData(data);
    }



    async function getData(data: CarSearch) {

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        setIsLoading(true);

        try {
            const responseData = await getCars(sortKey || 'id', "10", sortMethod || 'ASC', ["customData","customer.customData"], deleteEmptyFieldsFromObject(data), abortControllerRef.current.signal);
            const carsParse = z.array(CarSchema).parse(responseData);
            setData(carsParse || []);
            setIsLoading(false);
        } catch (error: unknown) {
            if (error instanceof CanceledError) {
                return;
            }
            handleApiErrors(error, showBoundary);
        }

    }

    async function getStructureAndSaveAtStore() {
        try {

            const structure = await getAccountsStructure({
                account_type: accountTypeName
            });

            dispatch(setCarsStructure(structure || []));

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }



    async function rowChosen<T>(row: T) {

        if (location.state?.from === "/documents/add") {
            const car = row as Car;
            let customer = car.customer;
    
            // if (car.account_number) {
            //     const customers = await getAccounts({
            //         account_number: car.account_number
            //     }, 'id', '0', 'ASC', ["customData"]);
    
            //     if (customers.length > 0) {
            //         customer = customers[0];
            //     }
            // }
    
            navigate(location.state?.from, {
                replace: true,
                state: {
                    ...location.state,
                    car: car,
                    customer: customer,
                }}
            );
        }

    }

    
    function editSearchStructure(){
        navigate('/structure', { replace: true, state: {
            from: location.pathname,
            fromType: 'search',
            structureName: "רכב",
            structureType: 'account',
            accountType: 'cars'
        } });
    }

    function editBrowseStructure(){
        navigate('/structure', { replace: true, state: {
            from: location.pathname,
            fromType: 'browse',
            structureName: "רכב",
            structureType: 'account',
            accountType: 'cars'
        } });
    }

    function reset() {

        const values = setAllValuesToNull(carSearchForm.getValues());

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                carSearchForm.setValue(key as keyof CarSearch, values[key as keyof CarSearch]);
            }
        }
    }


    return (
        <StyledCars>
            {searchStructure ?
                <SearchTable structure={searchStructure} setStructure={editSearchStructure} reset={reset} useForm={carSearchForm} style={{ backgroundColor: "rgb(35, 48, 68)", padding: 5, marginButton: 20 }} /> :
                <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />}
            {tableStructure ?
                <DataTable structure={tableStructure} setStructure={editBrowseStructure} sortKey={sortKey} setSortKey={handleSortKeyChanged} sortMethod={sortMethod} setSortMethod={handleSortMethodChanged} data={data} isLoading={isLoading} options={options} selectRow={location.state?.from ? true : false} onSelectRow={rowChosen} /> :
                <Skeleton variant="rectangular" height={150} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />}
        </StyledCars>
    );
}

export default Cars;