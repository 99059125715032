import { Typography } from "@mui/material"
import MuiInput from "../../../../components/mui-input/MuiInput"
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { DocumentPaymentForm } from "../../../../models/document-payment";

// local data
import { paymentFieldsStructure } from "../../../../local-data/localData";
import { DocumentStructure } from "../../../../models/document-structure";
import { Account } from "../../../../models/account";


interface PaymentsProps {

    method: "add" | "edit";

    paymentsForm: UseFormReturn<{ payments: DocumentPaymentForm[] }>;
    paymentsFieldArray: UseFieldArrayReturn<{ payments: DocumentPaymentForm[] }, "payments", "id">;

    kupaAccounts: Account[];

    deleteRow: (rowIndex: number, type: string) => void;

    isFocused: (baseName: string, inputName: string) => boolean;

    isDocumentClosed?: boolean;

}



function Payments({ method, paymentsForm, paymentsFieldArray, kupaAccounts, deleteRow, isFocused, isDocumentClosed }: PaymentsProps) {

    //add a new payment line
    function addPaymentLine() {

        paymentsForm.reset({ payments: [...paymentsForm.getValues().payments, { total: 0 }] });

    }


    function disablePaymentField(field: DocumentStructure, row: number): boolean {

        const kupa_id = paymentsForm.getValues("payments")[row]["kupa_account_id"];

        const arrayForDefualt = ["total", "kupa_account_id"];
        const arrayForCheck = ["total", "kupa_account_id", "bank_account_number", "bank_branch_number", "bank_name", "bank_number", "check_number", "to_date", "tax"];
        const arrayForCard = ["total", "kupa_account_id", "to_date", "tax"];
        const arrayForBankTransfer = ["total", "kupa_account_id", "bank_account_id"];

        if (kupa_id) {
            const kupa = kupaAccounts.find(kupa => kupa.id === kupa_id);


            if (kupa?.payment_type === "שיקים") {

                if (!arrayForCheck.includes(field.field_name)) {
                    return true;
                }

                else {
                    return false;
                }
            }

            if (kupa?.payment_type === "מזומן") {

                if (!arrayForDefualt.includes(field.field_name)) {
                    return true;
                }

                else {
                    return false;
                }
            }

            if (kupa?.payment_type === "אשראי") {

                if (!arrayForCard.includes(field.field_name)) {
                    return true;
                }

                else {
                    return false;
                }
            }

            if (kupa?.payment_type === "העברות") {

                if (!arrayForBankTransfer.includes(field.field_name)) {
                    return true;
                }

                else {
                    return false;
                }
            }
        }

        //defualt
        else {
            if (!arrayForDefualt.includes(field.field_name)) {
                return true;
            }

            else {
                return false;
            }
        }

        return false;

    }

    if (method === "add") {
        return (
            <div className="payment">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            תשלום:
                        </Typography>
                    </div>
                </div>
                <table>
                    <tbody>
                        {
                            paymentsFieldArray.fields.map((field, rowIndex) => (

                                <tr key={field.id}>
                                    <td className="row-number">{rowIndex + 1}.</td>
                                    {
                                        paymentFieldsStructure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                            return (field.type === "תשלום") ? <td key={field.field_name}><MuiInput field={field} variant='outlined' useForm={paymentsForm} prefix={`payments.${rowIndex}.`} showRequired autoFocus={isFocused(`payments.${rowIndex}.`, field.field_name)} disabled={disablePaymentField(field, rowIndex)} /></td> : null
                                        })
                                    }
                                    <td><button onClick={() => { deleteRow(rowIndex, 'payment') }}>X</button></td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
                <button onClick={addPaymentLine}>הוספת שורה</button>
            </div>
        )
    }

    else if (method === "edit") {
        return (
            <div className="payment">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            תשלום:
                        </Typography>
                    </div>
                </div>
                <table>
                    <tbody>
                        {
                            paymentsFieldArray.fields.map((field, rowIndex) => (

                                <tr key={field.id}>
                                    <td className="row-number">{rowIndex + 1}.</td>
                                    {
                                        paymentFieldsStructure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                            return (field.type === "תשלום") ? <td key={field.field_name}><MuiInput field={field} variant='outlined' useForm={paymentsForm} prefix={`payments.${rowIndex}.`} showRequired autoFocus={isFocused(`payments.${rowIndex}.`, field.field_name)} disabled={disablePaymentField(field, rowIndex) || isDocumentClosed} /></td> : null
                                        })
                                    }
                                    <td><button onClick={() => { deleteRow(rowIndex, 'payment') }} disabled={isDocumentClosed}>X</button></td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
                <button onClick={addPaymentLine} disabled={isDocumentClosed}>הוספת שורה</button>
            </div>
        )
    }

}

export default Payments