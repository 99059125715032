import styled from '@emotion/styled'

export const StyledLogin = styled.div`

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


    .page{
        position: relative;
        background-color: ${({theme})=> theme.colors.background_color};;
        max-width:  470px;
        min-height: 70vh;
        padding: 40px;
        margin: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        border-radius: 10px;
    }

    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 25px;
    }

    button{
        height: 40px;
    }

    .fields{
        gap: 15px;
    }

    .register-link{
        cursor: pointer;
        margin-top: 55px;
    }
`