import { z } from "zod";
import { customErrorMap } from "../services/zodService";
import { AccountAddSchema, AccountEditSchema, AccountSchema } from "./account";
import { DocumentCustomerCustomDataFormSchema, DocumentCustomerCustomDataSchema } from "./document-customer-custom-data";

z.setErrorMap(customErrorMap);

export const DocumentCustomerSchema = AccountSchema.extend({

    id: z.number(),
    
    document_id: z.number(), // document object id.
    customer_id: z.number(), // customer object id.

    custom_data: z.array(DocumentCustomerCustomDataSchema).optional(),

});

export const DocumentCustomerEditSchema = AccountEditSchema.extend({

    id: z.number(),

    document_id: z.number().optional(), // document object id.
    customer_id: z.number(), // customer object id.

    custom_data: z.array(DocumentCustomerCustomDataFormSchema).optional(),

});

export const DocumentCustomerAddSchema = AccountAddSchema.extend({

    id: z.number().optional(),

    document_id: z.number().optional(), // document object id.
    customer_id: z.number(), // customer object id.

    custom_data: z.array(DocumentCustomerCustomDataFormSchema).optional(),

});

export type DocumentCustomer = z.infer<typeof DocumentCustomerSchema>
export type DocumentCustomerEdit = z.infer<typeof DocumentCustomerEditSchema>
export type DocumentCustomerAdd = z.infer<typeof DocumentCustomerAddSchema>