import styled from '@emotion/styled'

export const StyledRegister = styled.div`

    height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */


    .page{
        position: relative;
        background-color: ${({ theme }) => theme.colors.background_color};;
        max-width:  470px;
        min-height: 70vh;
        max-height: 90vh;
        padding: 40px;
        margin: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        border-radius: 10px;
    }

    form{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        width: 100%;
        overflow: auto;
        scrollbar-width: none;
    }

    .page::-webkit-scrollbar {
        display: none;  /* For Webkit-based browsers (Chrome, Safari, etc.) */
    }

    .section{
        display: flex;
        text-align: left;
        gap: 15px;
        margin: 20px 0;
    }


    .login-link{
        cursor: pointer;
        margin-top: 30px;
    }

    button{
        height: 40px;
    }
`