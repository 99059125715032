import { Typography } from "@mui/material"
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { DocumentClosingForm } from "../../../../models/document-closing";
import { Document, DocumentAdd, DocumentEdit } from "../../../../models/document";
import SelectInvoices from "../select-invoices/SelectInvoices";
import { padWithZeros } from "../../../../helpers/helpers";
import { Account } from "../../../../models/account";
import { DocumentSettings } from "../../../../models/document-settings";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//state properties:
interface LocationState {
    customer?: Account | null;
}

interface InvoicesProps {

    method: "add" | "edit";

    documentForm: UseFormReturn<DocumentAdd> | UseFormReturn<DocumentEdit>;
    documentClosingForm: UseFormReturn<{ documents: DocumentClosingForm[] }>;
    documentsClosingFieldArray: UseFieldArrayReturn<{ documents: DocumentClosingForm[] }, "documents", "id">;

    settings: DocumentSettings | undefined;
    incomes: Account[] | undefined;

    calcDocument: (settings: DocumentSettings | undefined, incomes: Account[] | undefined, documentsToClose?: Document[]) => void;
    deleteRow: (rowIndex: number, type: string) => void;
    setDocumentsToClose: (selectedDocuments: Document[]) => void;

    isDocumentClosed?: boolean;
}

function Invoices({ method, documentForm, documentClosingForm, documentsClosingFieldArray, settings, incomes, calcDocument, deleteRow, setDocumentsToClose, isDocumentClosed }: InvoicesProps) {

    const { state } = useLocation() as { state: LocationState }; // state data from another page

    const [isSelectInvoicesDialogOpen, setIsSelectInvoicesDialogOpen] = useState<boolean>(false); // set if open select invoices dialog

    // actions after getting the document settings.
    useEffect(() => {
        if (settings) {

            // show select invoices in kabala if needed.
            if (settings.name === 'קבלה' && state?.customer) {
                setIsSelectInvoicesDialogOpen(true);
            }
        }
    }, [settings]);

    if(method === "add"){

        documentForm = documentForm as UseFormReturn<DocumentAdd>;

        return (
            <div className="invoices">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            בחירת חשבוניות לתשלום:
                        </Typography>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>מסמך</th>
                            <th>יתרה</th>
                            <th>יתרה חדשה</th>
                            <th>סטטוס</th>
                            <th>סטטוס חדש</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentsClosingFieldArray.fields.map((document, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{document.name}</td>
                                <td>{document.old_balance}₪</td>
                                <td>{document.balance}₪</td>
                                <td>{document.old_status}</td>
                                <td>{document.status}</td>
                                <td className="delete-row"><button onClick={() => { deleteRow(rowIndex, 'document-closing') }}>X</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button onClick={() => { setIsSelectInvoicesDialogOpen(true) }} disabled={!documentForm.getValues('customer.account_number')}>בחירת חשבוניות</button>
    
    
                <SelectInvoices
    
                    open={isSelectInvoicesDialogOpen}
                    onClose={(selectedDocuments?: Document[]) => {
    
                        setIsSelectInvoicesDialogOpen(false);
    
                        if (selectedDocuments) {
                            setDocumentsToClose(selectedDocuments);
    
                            const formToUpadte: DocumentClosingForm[] = selectedDocuments.map(document => ({
                                ...document,
                                name: `${document.document_settings?.name} ${document.document_number}`,
                                document_closed_id: document.id,
                                old_status: document.status,
                                balance: document.balance!,
                                old_balance: document.balance!,
                                amount: document.balance!,
                                reference: `${document.document_settings?.short_name} ${padWithZeros(document.document_number, 7)}`
                            }));
    
                            documentClosingForm.reset({ documents: formToUpadte });
                            calcDocument(settings, incomes, selectedDocuments);
                        }
    
                    }}
                    accountId={documentForm.getValues("customer.id")}
                />
    
            </div>
        )
    }

    else if(method === "edit"){
        
        documentForm = documentForm as UseFormReturn<DocumentEdit>;

        return (
            <div className="invoices">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            בחירת חשבוניות לתשלום:
                        </Typography>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>מסמך</th>
                            <th>יתרה</th>
                            <th>יתרה חדשה</th>
                            <th>סטטוס</th>
                            <th>סטטוס חדש</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentsClosingFieldArray.fields.map((document, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{document.name}</td>
                                <td>{document.old_balance}₪</td>
                                <td>{document.balance}₪</td>
                                <td>{document.old_status}</td>
                                <td>{document.status}</td>
                                <td className="delete-row"><button onClick={() => { deleteRow(rowIndex, 'document-closing') }} disabled={isDocumentClosed}>X</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button onClick={() => { setIsSelectInvoicesDialogOpen(true) }} disabled={!documentForm.getValues('customer.account_number') || isDocumentClosed }>בחירת חשבוניות</button>
    
    
                <SelectInvoices
    
                    open={isSelectInvoicesDialogOpen}
                    onClose={(selectedDocuments?: Document[]) => {
    
                        setIsSelectInvoicesDialogOpen(false);
    
                        if (selectedDocuments) {
                            setDocumentsToClose(selectedDocuments);
    
                            const formToUpadte: DocumentClosingForm[] = selectedDocuments.map(document => ({
                                ...document,
                                name: `${document.document_settings?.name} ${document.document_number}`,
                                document_closed_id: document.id,
                                old_status: document.status,
                                balance: document.balance!,
                                old_balance: document.balance!,
                                amount: document.balance!,
                                reference: `${document.document_settings?.short_name} ${padWithZeros(document.document_number, 7)}`
                            }));
    
                            documentClosingForm.reset({ documents: formToUpadte });
                            calcDocument(settings, incomes, selectedDocuments);
                        }
    
                    }}
                    accountId={documentForm.getValues("customer.id")}
                />
    
            </div>
        )
    }
    
}

export default Invoices