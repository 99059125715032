import { z } from "zod";
import { customErrorMap } from "../services/zodService";

z.setErrorMap(customErrorMap);


export const ItemEntrySchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    item_id: z.number(),
    sku: z.string().min(1),
    out: z.number(),
    in: z.number(),
    balance: z.number(),
    price: z.number(),
    account_name: z.string(),
    account_number: z.number(),

    created_at: z.string(),
    updated_at: z.string(), 
    
});

export type ItemEntry = z.infer<typeof ItemEntrySchema>
