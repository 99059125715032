import { Button, OutlinedInput, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { StyledLogin } from "./login.styled";
import { useState } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AuthContext";
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import { UserAddSchema } from '../../../models/user';
import { z } from "zod";
import Link from '@mui/material/Link';
import Metal from "../../../assets/images/auth/metal";

const loginSchema = UserAddSchema.pick({
    email: true,
    password: true
}).required();

type LoginModel = z.infer<typeof loginSchema>



function Login() {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const { user, loginUser } = useAuth();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginModel>({
        resolver: zodResolver(loginSchema),
    });


    const onSubmit: SubmitHandler<LoginModel> = async (data) => {

        await loginUser(data.email, data.password);

        if (user) {
            navigate('/');
        }
    };


    return (
        <StyledLogin>
            <Metal />
            <Grid container sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <Grid item xs={11} md={9} lg={7} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", alignItems: "center", gap: 3 }} className="page">
                    <Typography variant="h3">התחברות</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="fields column">

                            <FormControl>
                                <TextField id="email" label="אימייל"
                                    error={errors?.email ? true : false}
                                    variant="outlined"
                                    helperText={errors?.email?.message}
                                    {...register("email")} />
                            </FormControl>


                            <FormControl variant="outlined" error={errors?.password ? true : false}>
                                <InputLabel htmlFor="outlined-adornment-password">סיסמה</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    {...register("password")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword((show) => !show)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{errors?.password?.message}</FormHelperText>
                            </FormControl>
                        </div>

                        <div className="button column">
                            <Button variant="contained" type="submit" disabled={isSubmitting} >התחבר</Button>
                            <Link onClick={() => { navigate("/register") }} className="register-link" sx={{ textDecoration: "none" }}>יצירת משתמש</Link>
                        </div>


                    </form>
                </Grid>
            </Grid>
        </StyledLogin>
    );
}

export default Login;