
import { StyledDocument } from "../Document.styled";

//react hooks
import { useEffect, useState } from "react";
import { Path, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";


// zod
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";


// models

import { Account, AccountSchema } from "../../../models/account";
import { AccountCustomDataForm } from "../../../models/account-custom-data";

import { Car as CarModel } from "../../../models/car";
import { CarCustomDataForm } from "../../../models/car-custom-data";

import { Document, DocumentEdit, DocumentEditSchema } from "../../../models/document";

import { DocumentItem, DocumentItemEdit, DocumentItemEditSchema } from "../../../models/document-item";
import { DocumentItemCustomDataForm } from "../../../models/document-item-custom-data";

import { DocumentWork, DocumentWorkEdit, DocumentWorkEditSchema } from "../../../models/document-work";
import { DocumentWorkCustomDataForm } from "../../../models/document-work-custom-data";

import { DocumentSettings, DocumentSettingsSchema } from "../../../models/document-settings";
import { DocumentStructure, DocumentStructureSchema } from "../../../models/document-structure";
import { DocumentPaymentForm, DocumentPaymentFormSchema } from "../../../models/document-payment";
import { DocumentClosingForm, DocumentClosingFormSchema } from "../../../models/document-closing";
import { DocumentDepositAdd, DocumentDepositAddSchema } from "../../../models/document-deposit";


// helpers
import { fixedNumber } from "../../../helpers/helpers";


// services
import { getDocumentsSettings } from "../../../services/documentsSettingsService";
import { getDocumentsStructure } from "../../../services/documentsStructureService";
import { getDocumentById, getNextDocumentNumber, saveDocument as saveDocumentService } from "../../../services/documentsService";
import { getAccounts } from "../../../services/accountsService";


// mui
import { Button, Skeleton } from "@mui/material";


// toast
import { toast } from 'react-toastify';
import { handleApiErrors, toastErrorHandling } from "../../../services/errorHandling";

// components

import Header from "../components/header/Header";
import Customer from "../components/customer/Customer";
import Car from "../components/car/Car";
import Bank from "../components/bank/Bank";
import Works from "../components/works/Works";
import Items from "../components/items/Items";
import Invoices from "../components/invoices/Invoices";
import Payments from "../components/payments/Payments";
import Deposit from "../components/deposit/Deposit";
import Title from "../components/title/Title";
import Summary from "../components/summary/Summary";


import { useErrorBoundary } from "react-error-boundary";
import { useParams } from 'react-router-dom';
import { useTheme } from "@emotion/react";

// import { DevTool } from '@hookform/devtools';

// import { Account } from "../../../models/account";
// import { Car } from "../../../models/car";

//state properties:
interface LocationState {

    selectedTicket?: Document | null;

    document_settings_id?: number | null;

    method?: string | null;

    document?: Document | null;

    reference_id?: number | null;
    reference?: string | null;

    customer?: Account | null;
    car?: CarModel | null;
    bank?: Account | null;

    works?: DocumentWork[] | null;
    chosenWork?: DocumentWork | null;
    workRowIndex?: number | null;

    items?: DocumentItem[] | null;
    chosenItem?: DocumentItem | null;
    itemRowIndex?: number | null;

    payments?: DocumentPaymentForm[] | null;

}

// *********************************************************************************************

// ------------------- forms array schemas -------------------------- //

// *********************************************************************************************


//create an array schema for works
const WorksFormSchema = z.object({
    works: z.array(DocumentWorkEditSchema),
});

type WorksFormType = z.infer<typeof WorksFormSchema>



//create an array schema for items
const ItemsFormSchema = z.object({
    items: z.array(DocumentItemEditSchema),
});

type ItemsFormType = z.infer<typeof ItemsFormSchema>



//create an array schema for items
const PaymentsFormSchema = z.object({
    payments: z.array(DocumentPaymentFormSchema),
})

type PaymentsFormType = z.infer<typeof PaymentsFormSchema>



// create an array schema for documentClosing
const ClosingFormSchema = z.object({
    documents: z.array(DocumentClosingFormSchema),
})

type ClosingFormType = z.infer<typeof ClosingFormSchema>



// create an array schema for deposits
const DepositsFormSchema = z.object({
    deposits: z.array(DocumentDepositAddSchema),
})

type DepostitsFormType = z.infer<typeof DepositsFormSchema>


// *********************************************************************************************

// ------------------- start of the component -------------------------- //

// *********************************************************************************************


function EditDocument() {

    // *********************************************************************************************

    // ------------------- variables -------------------------- //

    const method = "edit";

    const { id } = useParams();

    const navigate = useNavigate();
    const { state, pathname } = useLocation() as { state: LocationState; pathname: string }; // state data from another page
    const { showBoundary } = useErrorBoundary();
    const theme = useTheme();

    const [documentsSettings, setDocumentsSettings] = useState<DocumentSettings[]>(); // all documents settings
    const [documentsStructure, setDocumentsStructure] = useState<DocumentStructure[]>(); // all documents fields structure
    const [incomes, setIncomes] = useState<Account[]>(); // all incomes accounts
    const [kupaAccounts, setKupaAccounts] = useState<Account[]>(); // all kupa accounts

    const [settings, setSettings] = useState<DocumentSettings>(); // this document settings
    const [structure, setStructure] = useState<DocumentStructure[]>(); // this document fields structure
    const [documentNumber, setDocumentNumber] = useState<number>(); // this document number
    const [workRowIndex, setWorkRowIndex] = useState<number>(0); // work row index clicked
    const [itemRowIndex, setItemRowIndex] = useState<number>(0); // item row index clicked
    const [focusedFieldName, setFocusedFieldName] = useState<string>();
    const [isSelectDepositsDialogOpen, setIsSelectDepositsDialogOpen] = useState<boolean>(false); // set if open select deposit dialog
    const [isSelectCashDialogOpen, setIsSelectCashDialogOpen] = useState<boolean>(false); // set if open select deposit dialog
    const [documentsToClose, setDocumentsToClose] = useState<Document[]>(); // invoices to close for receipt
    const [isSaving, setIsSaving] = useState<boolean>(false); // set if this doc is saving data right now

    const [isDocumentClosed, setIsDocumentClose] = useState<boolean>();


    // *********************************************************************************************


    // ------------------- end of variables -------------------------- //


    // ------------------- forms -------------------------- //


    // *********************************************************************************************



    const documentForm = useForm<DocumentEdit>({
        resolver: zodResolver(DocumentEditSchema),
        defaultValues: {
            customer: null,
            car: null,
            // total: 0,
            // discount: 0,
            // vat: 0,
            // to_pay: 0,
            // balance: 0,
        }
    });


    const worksForm = useForm<WorksFormType>({
        resolver: zodResolver(WorksFormSchema),
        defaultValues: {
            works: [
                {
                    hours: 1,
                    price: 0,
                    discount: 0,
                    net: 0,
                    total: 0,
                }
            ]
        }
    });

    const itemsForm = useForm<ItemsFormType>({
        resolver: zodResolver(ItemsFormSchema),
        defaultValues: {
            items: [
                {
                    amount: 1,
                    price: 0,
                    discount: 0,
                    net: 0,
                    total: 0,
                }
            ]
        }
    });

    const paymentsForm = useForm<PaymentsFormType>({
        resolver: zodResolver(PaymentsFormSchema),
        defaultValues: {
            payments: [
                {
                    total: 0,
                    // kupa_account_id: 0
                    // price: 0,
                    // discount: 0,
                    // net: 0,
                }
            ]
        }
    });

    const documentClosingForm = useForm<ClosingFormType>({
        resolver: zodResolver(ClosingFormSchema),
        defaultValues: {
            documents: []
        }
    });


    const documentDepositsForm = useForm<DepostitsFormType>({
        resolver: zodResolver(DepositsFormSchema),
        defaultValues: {
            deposits: []
        }
    });


    // *********************************************************************************************


    // ------------------- end of forms -------------------------- //


    // ------------------- forms arrays -------------------------- //


    // *********************************************************************************************


    const itemsFieldArray = useFieldArray({
        name: 'items',
        control: itemsForm.control
    });

    const worksFieldArray = useFieldArray({
        name: 'works',
        control: worksForm.control
    });

    const paymentsFieldArray = useFieldArray({
        name: 'payments',
        control: paymentsForm.control
    });

    const documentsClosingFieldArray = useFieldArray({
        name: 'documents',
        control: documentClosingForm.control
    });

    const depositsFieldArray = useFieldArray({
        name: 'deposits',
        control: documentDepositsForm.control
    });


    // *********************************************************************************************


    // ------------------- end of forms arrays -------------------------- //


    // ------------------- use effects -------------------------- //


    // *********************************************************************************************


    //actions to run one time when component finished rendering
    useEffect(() => {

        getDocumentData();

        // fetch all data (documents settings, documents structures, incomes, kupaAccounts)
        fetchAllData();

        // set forms data from other page by state.
        initialDocumentsFormByState();

    }, []);


    // *********************************************************************************************


    // set this document settings and fields structure one time, after getting documentsSettings and documentsStructure from server.
    useEffect(() => {

        if (documentsSettings && documentsStructure) {

            const documentSettingsId: number = state?.document_settings_id || documentsSettings[0].id;

            if (!settings) {
                setDocumentSettings(documentsSettings, documentSettingsId);
            }

            if (!structure) {
                setDocumentStructure(documentsStructure, documentSettingsId);
            }
        }

    }, [documentsSettings, documentsStructure]);


    // *********************************************************************************************


    // actions after getting the document settings.
    useEffect(() => {
        if (settings) {

            // get the document number.
            getDocumentNumber(settings.id);

        }
    }, [settings]);


    // *********************************************************************************************


    // calc document.
    useEffect(() => {
        if (settings && incomes && !isDocumentClosed) {
            calcDocument(settings, incomes);
        }
    }, [settings, incomes]);


    // *********************************************************************************************


    // when user change document type: reset forms and update document settings and structure.
    useEffect(() => {

        const subscription = documentForm.watch((data, { name }) => {

            if (name === "document_settings_id") {

                const documentSettingsId = data.document_settings_id;

                if (documentsSettings && documentsStructure && typeof documentSettingsId === 'number') {
                    setDocumentSettings(documentsSettings, documentSettingsId);
                    setDocumentStructure(documentsStructure, documentSettingsId);
                    resetAllData(documentSettingsId);
                }

            }
        });

        return () => subscription.unsubscribe();

    }, [documentForm.watch("document_settings_id")]);



    // *********************************************************************************************


    //update item line when one field in the line changes.
    useEffect(() => {

        const subscription = itemsForm.watch((_, { name }) => {

            if (name) {
                updateItemRow(name);

            }
        });

        return () => subscription.unsubscribe();

    }, [itemsForm.watch()]);


    // *********************************************************************************************


    //update work line when one field in the line changes.
    useEffect(() => {

        const subscription = worksForm.watch((_, { name }) => {

            if (name) {
                updateWorkRow(name);
            }
        });

        return () => subscription.unsubscribe();

    }, [worksForm.watch()]);


    // *********************************************************************************************


    //update payment line when one field in the line changes.
    useEffect(() => {

        const subscription = paymentsForm.watch((_, { name }) => {

            if (name) {
                updatePaymentRow(name);
            }

            calcDocument(settings, incomes, documentsToClose);
        });

        return () => subscription.unsubscribe();

    }, [paymentsForm.watch()]);


    // *********************************************************************************************


    // calc total payment of documents closing
    useEffect(() => {

        const subscription = documentForm.watch((value, { name }) => {

            if (name === "receipt") {
                calcDocumentsClosing(Number(value[name]));
            }
        });

        return () => subscription.unsubscribe();

    }, [documentForm.watch("receipt")]);


    // *********************************************************************************************


    // ------------------- end of use effects -------------------------- //


    // ------------------- fetch data functions -------------------------- //


    // *********************************************************************************************

    const getDocumentData = async () => {

        try {

            if (id) {

                const loads = [
                    "documentSettings",
                    "customer.customData",
                    "car.customData",
                    "works.customData",
                    "items.customData",
                    "payments",
                    "deposits",
                    "closing"
                ];

                const document: Document = await getDocumentById(id, loads);

                setDocumentNumber(document.document_number);
                setSettings(document.document_settings!);
                setIsDocumentClose(document.status === "סגור" || document.status === "סגור חלקית" || !!document.document_settings?.accounting)

                documentForm.reset({...document})

                if ( document.works && document.works.length > 0) {
                    worksForm.reset({ works: document.works });
                }

                if (document.items && document.items.length > 0) {
                    itemsForm.reset({ items: document.items });
                }

                if (document.payments && document.payments.length > 0) {
                    paymentsForm.reset({ payments: document.payments });
                }

                if (document.closing && document.closing.length > 0) {
                    documentClosingForm.reset({ documents: document.closing });
                }

                if (document.deposits && document.deposits.length > 0) {
                    documentDepositsForm.reset({ deposits: document.deposits });
                }

                // setDocumentsToClose(document.documents_closing!);

                console.log("document: ", document);
            }

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }


    }

    function fetchAllData() {

        // fetch all documents settings
        getDocumentsSettingsData();

        // fetch all documents fields structure
        getDocumentsStructureData();

        // fetch incomes accounts
        getIncomes();

        // fetch kupa accounts
        getKupaAccounts();
    }


    // *********************************************************************************************


    // get all documents settings, and update the state.
    const getDocumentsSettingsData = async (): Promise<void> => {

        try {

            const documentsSettings: DocumentSettings[] = await getDocumentsSettings();
            const documentsSettingsParse = z.array(DocumentSettingsSchema).parse(documentsSettings);
            setDocumentsSettings(documentsSettingsParse);

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }

    // *********************************************************************************************


    //get all documents fields structure, and update the state.
    const getDocumentsStructureData = async (): Promise<void> => {
        try {

            const documentsStructure: DocumentStructure[] = await getDocumentsStructure();
            const documentsStructureParse = z.array(DocumentStructureSchema).parse(documentsStructure);
            setDocumentsStructure(documentsStructureParse);

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    };

    // *********************************************************************************************


    // fetch incomes accounts
    async function getIncomes(): Promise<void> {

        try {
            const data = await getAccounts({ account_type: "הכנסה" }, 'id', '0', 'ASC', ["customData", "entries"]);
            const incomesParse = z.array(AccountSchema).parse(data);
            setIncomes(incomesParse);

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }

    }

    // *********************************************************************************************


    // fetch kupa accounts
    async function getKupaAccounts() {

        try {
            const kupaAccounts: Account[] = await getAccounts({ account_type: "קופה" }, 'id', '0', 'ASC');
            const kupaAccountsParse = z.array(AccountSchema).parse(kupaAccounts);
            setKupaAccounts(kupaAccountsParse);

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }

    }


    // *********************************************************************************************


    //get the number of the new document by it's type.
    const getDocumentNumber = async (documentSettingsId: number) => {

        setDocumentNumber(undefined);

        try {
            const documentNumber: number = await getNextDocumentNumber(documentSettingsId);
            setDocumentNumber(documentNumber);
            documentForm.setValue("document_number", documentNumber);
        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }


    // *********************************************************************************************


    // ------------------- end of fetch data functions -------------------------- //


    // ------------------- update states functions -------------------------- //


    // *********************************************************************************************


    //find and set the settings of the current document type
    function setDocumentSettings(documentsSettings: DocumentSettings[], documentSettingsId: number): void {

        const documentSettings = documentsSettings.find(documentsSetting => documentsSetting.id === documentSettingsId);
        setSettings(documentSettings);
    }


    // *********************************************************************************************


    // find this document structure 
    function setDocumentStructure(documentStructure: DocumentStructure[], documentSettingsId: number): void {

        const documentStructureCopy = [...documentStructure];

        documentStructureCopy.forEach(structure => {
            const showFormObj = structure.show_form_array.find(showForm => showForm.document_settings_id === documentSettingsId);
            structure.show_form = showFormObj ? showFormObj.show_form : false;
        });

        setStructure(documentStructureCopy);
    }


    // *********************************************************************************************


    // ------------------- end of update states functions -------------------------- //


    // ------------------- update forms functions -------------------------- //


    // *********************************************************************************************



    //set data in the forms from another page by state.
    function initialDocumentsFormByState() {

        if (!state) {
            return;
        }

        // set document type
        // if (state.document_settings_id) {
        //     documentForm.setValue('document_settings_id', state.document_settings_id);
        // }

        // if customer selected
        if (state.customer) {
            documentForm.setValue('account_id', state.customer.id);
            documentForm.setValue("customer", { ...state.customer, customer_id: state.customer.id });
        }

        // if car selected
        if (state.car) {
            documentForm.setValue("car", { ...state?.car, car_id: state.car.id });
        }

        // if reference selected
        if (state.method === 'reference' && state.document) {

            documentForm.setValue("reference_id", state.document.id);
            documentForm.setValue("reference", `${state.document.document_settings?.name} ${state.document.document_number}`);

            if (state.document?.customer) {
                documentForm.setValue("account_id", state.document.account_id);
                documentForm.setValue("customer", state.document.customer);
            }

            if (state.document?.car) {
                documentForm.setValue("car", state.document.car);
            }

        }

        //set works rows data from state
        setWorkRow();

        //set items rows data from state
        setItemRow();

        //set payments from state
        setPaymentRow();


    }

    // *********************************************************************************************


    // update work row after one field changed
    function updateWorkRow(name: Path<WorksFormType>) {
        setFocusedFieldName(name);

        const firstDotIndex = name.indexOf('.');
        const secondDotIndex = name.indexOf('.', firstDotIndex + 1);

        const rowIndex = Number(name.substring(firstDotIndex + 1, secondDotIndex));
        const fieldName = name?.slice(secondDotIndex + 1);

        const hours = worksForm.getValues(`works.${rowIndex}.hours`) || 1;
        const price = worksForm.getValues(`works.${rowIndex}.price`);
        const discount = worksForm.getValues(`works.${rowIndex}.discount`);
        const net = worksForm.getValues(`works.${rowIndex}.net`);
        const total = worksForm.getValues(`works.${rowIndex}.total`);

        let updatedNet;
        let updatedPrice;

        switch (fieldName) {

            case 'price': case 'discount':

                updatedNet = price * (1 - ((discount || 0) / 100));

                worksFieldArray.update(rowIndex, {
                    ...worksForm.getValues().works[rowIndex],
                    hours: hours,
                    net: fixedNumber(updatedNet),
                    total: fixedNumber(updatedNet * (hours || 1))
                });

                break;

            case 'amount':

                worksFieldArray.update(rowIndex, {
                    ...worksForm.getValues().works[rowIndex],
                    price: fixedNumber((net || 0) / (1 - ((discount || 0) / 100))),
                    total: fixedNumber((net || 0) * (hours || 1))
                });

                break;

            case 'net':
                worksFieldArray.update(rowIndex, {
                    ...worksForm.getValues().works[rowIndex],
                    discount: fixedNumber((1 - ((net || 0) / price)) * 100),
                    total: fixedNumber((net || 0) * hours)
                });
                break;

            case 'total':

                updatedPrice = (total || 0) / ((hours || 1) * ((1 - ((discount || 0) / 100)) || 1));

                worksFieldArray.update(rowIndex, {
                    ...worksForm.getValues().works[rowIndex],
                    price: fixedNumber(updatedPrice),
                    net: fixedNumber(updatedPrice * (1 - ((discount || 0) / 100)))
                });

                break;

            default:
                break;
        }

        worksForm.setFocus(name);

        //calc the new payment section
        calcDocument(settings, incomes, documentsToClose);
    }


    // *********************************************************************************************


    // update item row after one field changed
    function updateItemRow(name: Path<ItemsFormType>) {
        setFocusedFieldName(name);

        const firstDotIndex = name.indexOf('.');
        const secondDotIndex = name.indexOf('.', firstDotIndex + 1);

        const rowIndex = Number(name.substring(firstDotIndex + 1, secondDotIndex));
        const fieldName = name?.slice(secondDotIndex + 1);


        const amount = itemsForm.getValues(`items.${rowIndex}.amount`) || 1;
        const price = itemsForm.getValues(`items.${rowIndex}.price`);
        const discount = itemsForm.getValues(`items.${rowIndex}.discount`);
        const net = itemsForm.getValues(`items.${rowIndex}.net`);
        const total = itemsForm.getValues(`items.${rowIndex}.total`);

        let updatedNet;
        let updatedPrice;


        switch (fieldName) {

            case 'price': case 'discount':

                updatedNet = price * (1 - ((discount || 0) / 100));

                itemsFieldArray.update(rowIndex, {
                    ...itemsForm.getValues().items[rowIndex],
                    amount: amount,
                    net: fixedNumber(updatedNet),
                    total: fixedNumber(updatedNet * (amount || 1))
                });

                break;

            case 'amount':

                itemsFieldArray.update(rowIndex, {
                    ...itemsForm.getValues().items[rowIndex],
                    price: fixedNumber((net || 0) / (1 - ((discount || 0) / 100))),
                    total: fixedNumber((net || 0) * (amount || 1))
                });

                break;

            case 'net':
                itemsFieldArray.update(rowIndex, {
                    ...itemsForm.getValues().items[rowIndex],
                    discount: fixedNumber((1 - ((net || 0) / price)) * 100),
                    total: fixedNumber((net || 0) * amount)
                });
                break;

            case 'total':

                updatedPrice = (total || 0) / ((amount || 1) * ((1 - ((discount || 0) / 100)) || 1));

                itemsFieldArray.update(rowIndex, {
                    ...itemsForm.getValues().items[rowIndex],
                    price: fixedNumber(updatedPrice),
                    net: fixedNumber(updatedPrice * (1 - ((discount || 0) / 100)))
                });

                break;

            default:
                break;
        }

        itemsForm.setFocus(name);

        //calc the new payment section
        calcDocument(settings, incomes, documentsToClose);
    }


    // *********************************************************************************************


    // update payment row after one field changed
    function updatePaymentRow(name: Path<PaymentsFormType>) {

        const row: number = Number(name.split('.')[1]);
        const fieldName = name.split('.').slice(2).join('.');

        //reset payment row fields if payment method changed
        if (fieldName === 'kupa_account_id') {

            const payments = paymentsForm.getValues('payments');
            const kupa = kupaAccounts?.find(kupa => kupa.id === payments[row].kupa_account_id);

            if (kupa?.payment_type) {
                payments[row] = {
                    total: payments[row].total,
                    kupa_account_id: payments[row].kupa_account_id,
                    payment_method: kupa.payment_type
                };

                paymentsForm.reset({ payments: payments });
            }

        }
    }


    // *********************************************************************************************


    // clean all data in the page.
    function resetAllData(documentSettingsId: number) {

        documentForm.reset({
            document_settings_id: documentSettingsId,
            customer: null,
            car: null,
            // total: 0,
            // discount: 0,
            // vat: 0,
            // to_pay: 0,
            // balance: 0,
        });

        worksForm.reset();
        itemsForm.reset();
        paymentsForm.reset();
        documentClosingForm.reset({ documents: [] });
        documentDepositsForm.reset({ deposits: [] });

        console.log("resetAllData");

        navigate(`/documents/add`, {
            replace: true,
            state: null
        });
    }


    // *********************************************************************************************


    //add a new work line
    function addWorkLine() {

        worksFieldArray.append({
            name: "",
            hours: 1,
            price: 0,
            discount: 0,
            net: 0,
            total: 0,
        });

        setWorkRowIndex(worksFieldArray.fields.length);

    }


    // *********************************************************************************************


    //add a new item line
    function addItemLine() {

        itemsFieldArray.append({
            name: "",
            amount: 1,
            price: 0,
            discount: 0,
            net: 0,
            total: 0,
        });

        setItemRowIndex(itemsFieldArray.fields.length);

    }


    // *********************************************************************************************


    //deleting a row by specify an index and row type
    function deleteRow(index: number, type: string): void {
        if (type === 'work') {
            setWorkRowIndex(0);
            worksFieldArray.remove(index);
            calcDocument(settings, incomes);
        }
        else if (type === 'item') {
            setItemRowIndex(0);
            itemsFieldArray.remove(index);
            calcDocument(settings, incomes);
        }
        else if (type === 'payment') {
            paymentsFieldArray.remove(index);
            calcDocument(settings, incomes, documentsToClose);
        }
        else if (type === 'document-closing') {
            documentsClosingFieldArray.remove(index);
            calcDocument(settings, incomes, documentsToClose);
        }
    }


    // *********************************************************************************************


    //set the items rows from state
    function setWorkRow() {

        if (state) {
            if (state.works || state.document?.works) {
                const works = state.works || (state.document?.works as DocumentWork[])?.map(work => {
                    return work.work_id ? { ...work, id: work.work_id } : { ...work, work_id: work.id };
                });

                worksForm.reset({ works: works });
            }

            if (state.chosenWork && typeof state.workRowIndex === "number") {

                const chosenWork: DocumentWork = state.chosenWork;
                const workRowIndex: number = state.workRowIndex;

                worksFieldArray.update(workRowIndex, {
                    ...chosenWork,
                    work_id: chosenWork.id,
                    hours: 1,
                    discount: 0,
                    net: chosenWork.price,
                    total: chosenWork.price
                });
            }
        }

    }


    // *********************************************************************************************


    // set the items rows from state
    function setItemRow() {

        if (state) {

            if (state.items || state.document?.items) {

                const items = state.items || (state.document?.items as DocumentItem[])?.map(item => {
                    return item.item_id ? { ...item, id: item.item_id } : { ...item, item_id: item.id };
                });

                itemsForm.reset({ items: items });
            }

            if (state.chosenItem && typeof state.itemRowIndex === "number") {

                const chosenItem: DocumentItem = state.chosenItem;
                const itemRowIndex: number = state.itemRowIndex;

                itemsFieldArray.update(itemRowIndex, {
                    ...chosenItem,
                    item_id: chosenItem.id,
                    amount: 1,
                    discount: 0,
                    net: chosenItem.price,
                    total: chosenItem.price
                });

            }
        }
    }


    // *********************************************************************************************


    //set the items rows after selecting a new item
    function setPaymentRow() {

        if (state) {
            const payments = state.payments || state.document?.payments as DocumentPaymentForm[];

            if (payments) {
                paymentsForm.reset({ payments: payments });
            }
        }

    }


    // *********************************************************************************************


    //update the document calculation section
    function calcDocument(settings: DocumentSettings | undefined, incomes: Account[] | undefined, documentsToClose?: Document[]): void {

        calcWorksAndItems(settings, incomes);

        calcKabala(settings, documentsToClose);


    }


    // *********************************************************************************************

    function calcWorksAndItems(settings: DocumentSettings | undefined, incomes: Account[] | undefined) {

        if (!settings || !incomes) {
            return;
        }

        if (settings.display_works === false && settings.display_items === false) {
            return;
        }

        let totalPriceWorks = 0; //total works price included vat
        let totalPriceWorksNoVat = 0; //total works price not included vat

        // calc for totalPriceWorks and totalPriceWorksNoVat
        if (settings.display_works) {

            worksForm.getValues().works.forEach(row => {

                const incomeCard = incomes.find(income => income.card_number === row.income_card);

                if (incomeCard && incomeCard?.vat_include === false) {
                    totalPriceWorksNoVat += row.total;
                }

                else {
                    totalPriceWorks += row.total;
                }

            });

            documentForm.setValue('works_total', fixedNumber(totalPriceWorks));

        }


        let totalPriceItems = 0; //total items price included vat
        let totalPriceItemsNoVat = 0; //total items price not included vat


        // calc for totalPriceItems and totalPriceItemsNoVat
        if (settings.display_items) {

            itemsForm.getValues().items.forEach(row => {

                const incomeCard = incomes.find(income => income.card_number === row.income_card);

                if (incomeCard && incomeCard?.vat_include === false) {
                    totalPriceItemsNoVat += row.total;
                }

                else {
                    totalPriceItems += row.total;
                }
            });

            documentForm.setValue('items_total', fixedNumber(totalPriceItems));

        }





        const totalWithVat = totalPriceWorks + totalPriceItems; //total to pay included vat
        const totalNoVat = totalPriceWorksNoVat + totalPriceItemsNoVat; //total to pay not included vat

        let total = totalNoVat + totalWithVat; //total to pay (works and items together)

        let vat; //vat to pay
        let to_pay = 0; //total to pay + vat
        let rounded = 0;

        // calc vat 
        if (settings?.circle) {
            const vatRate = 17 / 100;
            vat = totalWithVat * vatRate;
            to_pay = Math.round(total + vat);
            let desired_total_With_Vat = (to_pay - totalNoVat) / (1 + vatRate);
            rounded = total - desired_total_With_Vat - totalNoVat;

            vat = desired_total_With_Vat * 0.17;
        }

        else {
            vat = (totalPriceWorks + totalPriceItems) * 0.17;
            to_pay = vat + total;
        }



        documentForm.setValue('total', fixedNumber(total));
        documentForm.setValue('include_vat', fixedNumber(totalWithVat - rounded));
        documentForm.setValue('not_include_vat', fixedNumber(totalNoVat));
        documentForm.setValue('vat', fixedNumber(vat, 3));
        documentForm.setValue('discount', fixedNumber(rounded));
        documentForm.setValue('discount_percentage', fixedNumber((rounded / total * 100) || 0, 3));
        documentForm.setValue('to_pay', fixedNumber(to_pay));


        if (settings.type === "חשבונית מס") {
            documentForm.setValue('balance', fixedNumber(to_pay));
        }


    }

    // *********************************************************************************************


    function calcKabala(settings: DocumentSettings | undefined, documentsToClose?: Document[]): void {

        if (!settings) {
            return;
        }


        let totalPricePayments = 0; //total payments price

        //calc for totalPricePayments
        paymentsForm.getValues().payments.forEach(row => {
            totalPricePayments += row.total;
        });



        // if heshbonit mas kabala
        if (settings.type === "חשבונית מס קבלה") {

            const heshbonitPrice = documentForm.getValues('to_pay');

            if (heshbonitPrice) {
                documentForm.setValue('balance', fixedNumber(heshbonitPrice - totalPricePayments));
            }

            documentForm.setValue('receipt', totalPricePayments);

        }

        // if kabala
        if (documentsToClose) {

            let to_pay = 0; // total heshboniot price

            documentsToClose?.forEach(document => {
                to_pay += (document.balance || 0);
            });

            documentForm.setValue('to_pay', fixedNumber(to_pay));
            documentForm.setValue('receipt', totalPricePayments);
            documentForm.setValue('balance', fixedNumber(to_pay - totalPricePayments));
        }


    }


    // *********************************************************************************************


    function calcDocumentsClosing(receipt: number) {

        let balance = receipt;

        const updateForm = documentClosingForm.getValues("documents").map(documentClosing => {

            if (documentClosing.old_balance <= balance) {

                const documentClosingToRetrun: DocumentClosingForm = {
                    ...documentClosing,
                    balance: 0,
                    status: 'סגור',
                    amount: documentClosing.balance,
                };

                balance = balance - documentClosing.balance;

                return documentClosingToRetrun;
            }

            else if (documentClosing.old_balance > balance && balance !== 0) {

                const updated_balance = documentClosing.old_balance - balance
                const documentClosingToRetrun: DocumentClosingForm = {
                    ...documentClosing,
                    balance: updated_balance,
                    status: 'סגור חלקית',
                    amount: balance,
                };

                balance = 0;

                return documentClosingToRetrun;
            }

            else if (balance === 0) {

                const documentClosingToRetrun: DocumentClosingForm = {
                    ...documentClosing,
                    balance: documentClosing.balance,
                    status: 'פתוח',
                    amount: balance,
                };

                return documentClosingToRetrun
            }
        });

        documentClosingForm.reset({ documents: updateForm });

    }



    // *********************************************************************************************


    // ------------------- end of update forms functions -------------------------- //


    // ------------------- navigation functions -------------------------- //


    // *********************************************************************************************



    //navigate to document browsing
    function browseDocument() {

        navigate(`/documents`, {
            replace: true,
            state: {

                from: location.pathname,
                method: "browse document",
                document_settings_id: settings?.id,


                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,
                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,
                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });

    }


    // *********************************************************************************************


    //navigate to document (reference) browsing
    function browseReference() {

        navigate(`/documents`, {
            replace: true,
            state: {

                from: location.pathname,
                method: "reference",
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,
                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,
                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,

                searchFormData: {
                    document_settings: {
                        accounting: 0
                    }
                }
            }
        });

    }


    // *********************************************************************************************


    function editDocumentSettings() {

        navigate(`/documents/settings/${settings?.id}`, {
            replace: true,
            state: {

                from: location.pathname,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,
                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,
                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    //navigate to customer browsing
    function browseCustomer() {

        navigate(`/customers`, {
            replace: true,
            state: {

                from: pathname,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });

    }


    // *********************************************************************************************


    //navigate to bank browsing
    function browseBank() {

        navigate(`/banks`, {
            replace: true,
            state: {

                from: pathname,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });

    }


    // *********************************************************************************************


    //navigate to car browsing
    function browseCar() {

        navigate(`/cars`, {
            replace: true,
            state: {
                ...state,
                from: pathname,
                document_settings_id: settings?.id,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });

    }


    // *********************************************************************************************


    //navigate to work browsing
    function browseWork() {

        navigate(`/works`, {
            replace: true,
            state: {
                ...state,
                from: pathname,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });

    }


    // *********************************************************************************************


    //navigate to item browsing
    function browseItem() {

        navigate(`/items`, {
            replace: true,
            state: {
                ...state,
                from: pathname,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });

    }


    // *********************************************************************************************


    //navigate to add customer
    function addCustomer() {

        navigate(`/customers/add`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    //navigate to add customer
    function addBank() {

        navigate(`/banks/add`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    //navigate to add car
    function addCar() {

        navigate(`/cars/add`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,

            }
        });
    }


    // *********************************************************************************************


    //navigate to add work
    function addWork() {

        navigate(`/works/add`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: settings?.id,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                work: worksForm.getValues().works[workRowIndex],
                workRowIndex: workRowIndex,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    //navigate to add item
    function addItem() {

        navigate(`/items/add`, {
            replace: true,
            state: {

                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                document: state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                item: itemsForm.getValues().items[itemRowIndex],
                itemRowIndex: itemRowIndex,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    //navigate to add customer
    function editCustomer() {

        navigate(`/customers/${documentForm.getValues().customer?.customer_id || documentForm.getValues().customer?.id}`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,

            }
        });
    }


    // *********************************************************************************************


    //navigate to add bank
    function editBank() {

        navigate(`/banks/${documentForm.getValues().customer?.customer_id || documentForm.getValues().customer?.id}`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,

            }
        });
    }


    // *********************************************************************************************


    //navigate to add customer
    function editCar() {

        navigate(`/cars/${documentForm.getValues().car?.car_id || documentForm.getValues().car?.id}`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,

            }
        });
    }


    // *********************************************************************************************


    //navigate to edit work
    function editWork() {

        navigate(`/works/${worksForm.getValues().works[workRowIndex]?.work_id || worksForm.getValues().works[workRowIndex]?.id}`, {
            replace: true,
            state: {
                ...state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                work: worksForm.getValues().works[workRowIndex],
                workRowIndex: workRowIndex,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    //navigate to edit item
    function editItem() {

        navigate(`/items/${itemsForm.getValues().items[itemRowIndex]?.item_id || itemsForm.getValues().items[itemRowIndex]?.id}`, {
            replace: true,
            state: {
                // ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                document: state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                item: itemsForm.getValues().items[itemRowIndex],
                itemRowIndex: itemRowIndex,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    function editAccountStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: pathname,
                fromType: 'form',
                structureName: "לקוח-ספק במסמך",
                structureType: 'document',
                documentType: 'לקוח-ספק',
                documntEnType: 'customer',

                documentName: settings?.name,

                document_settings_id: documentForm.getValues("document_settings_id"),

                document: state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    function editCarStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: pathname,
                fromType: 'form',
                structureName: "רכב במסמך",
                structureType: 'document',
                documentType: 'רכב',
                documntEnType: 'car',

                documentName: settings?.name,

                document_settings_id: documentForm.getValues("document_settings_id"),

                document: state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    function editWorkStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: pathname,
                fromType: 'form',
                structureName: "עבודה במסמך",
                structureType: 'document',
                documentType: 'עבודה',
                // documentSettingsID: settings?.id,
                documentName: settings?.name,

                document_settings_id: documentForm.getValues("document_settings_id"),

                document: state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    function editItemStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: pathname,
                fromType: 'form',
                structureName: "פריט במסמך",
                structureType: 'document',
                documentType: 'פריט',

                documentName: settings?.name,


                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                payments: paymentsForm.getValues().payments,

                reference: documentForm.getValues().reference,
            }
        });
    }


    // *********************************************************************************************


    // ------------------- end of navigation functions -------------------------- //


    // -------------------  functions -------------------------- //


    // *********************************************************************************************



    //return the focus to the field that was changed
    function isFocused(baseName: string, inputName: string): boolean {
        return `${baseName}${inputName}` === focusedFieldName;
    }


    // *********************************************************************************************


    function updateCustomDataArray(customDataArray: CarCustomDataForm[] | AccountCustomDataForm[] | DocumentWorkCustomDataForm[] | DocumentItemCustomDataForm[] | undefined | null) {

        if (!customDataArray) {
            return;
        }

        for (let i = 0; i < customDataArray.length; i++) {
            if (!(i in customDataArray)) {
                customDataArray[i] = {
                    field_index: i,
                    // field_value: null
                }; // Fill empty slots with an empty object
            }
            else {
                customDataArray[i] = {
                    id: customDataArray[i].id,
                    field_index: i,
                    field_value: customDataArray[i].field_value
                };
            }
        }
    }


    // *********************************************************************************************


    // ------------------- end of functions -------------------------- //


    // -------------------  add document functions -------------------------- //


    // *********************************************************************************************


    //add a new document with all the forms data
    const saveDocument = async () => {

        if ((settings?.display_works || settings?.display_items) && documentForm.getValues("to_pay") === 0) {
            alert("הסכום לתשלום לא יכול להיות 0.");
            return;
        }

        if ((settings?.display_works || settings?.display_items) && settings?.display_payments && documentForm.getValues("balance") !== 0) {
            alert("ההפרש צריך להיות 0");
            return;
        }

        console.log("addDocument worksForm.getValues() ", worksForm.getValues());
        console.log("addDocument itemsForm.getValues() ", itemsForm.getValues());
        console.log("addDocument paymentsForm.getValues() ", paymentsForm.getValues());
        console.log("addDocument documentForm.getValues() ", documentForm.getValues());

        // documentForm.setValue("document_number", documentNumber!);
        // documentForm.setValue("document_settings_id", settings!.id);

        documentForm.setValue("status", settings?.type === "מסמך רגיל" || settings?.type === "כרטיס עבודה" || settings?.type === "חשבונית מס" ? "פתוח" : "סגור");

        const updatedcustomerCustomData = documentForm.getValues("customer.custom_data");
        const updatedcarCustomData = documentForm.getValues("car.custom_data");
        const updatedWorksCustomData = worksForm.getValues("works");
        const updatedItemsCustomData = itemsForm.getValues("items");

        updateCustomDataArray(updatedcustomerCustomData);
        updateCustomDataArray(updatedcarCustomData);

        updatedWorksCustomData.forEach((work) => {
            updateCustomDataArray(work.custom_data);
        });


        updatedItemsCustomData.forEach((item) => {
            updateCustomDataArray(item.custom_data);
        });

        documentForm.setValue("customer.custom_data", updatedcustomerCustomData);
        documentForm.setValue("car.custom_data", updatedcarCustomData);
        worksForm.setValue("works", updatedWorksCustomData);
        itemsForm.setValue("items", updatedItemsCustomData);

        if (!settings?.display_car || !documentForm.getValues("car.account_number")) {
            documentForm.setValue("car", undefined);
        }



        try {

            //add works to documentForm
            if (settings?.display_works) {

                await new Promise<void>((resolve, reject) => {
                    worksForm.handleSubmit((data: { works: DocumentWorkEdit[] }) => {
                        const works = data.works.filter((work) => !(work.total === 0 && work.name === "" && work.work_code === ""));
                        documentForm.setValue("works", works);
                        resolve();
                    }, (errors) => {
                        reject(errors);
                    })();
                });
            }


            //add items to documentForm
            if (settings?.display_items) {

                await new Promise<void>((resolve, reject) => {
                    itemsForm.handleSubmit((data: { items: DocumentItemEdit[] }) => {
                        const items = data.items.filter((item) => !(item.total === 0 && item.name === "" && item.sku === ""));
                        documentForm.setValue("items", items);
                        resolve();
                    }, (errors) => {
                        reject(errors);
                    })();
                });
            }

            //add payments to documentForm
            if (settings?.display_payments) {

                await new Promise<void>((resolve, reject) => {
                    paymentsForm.handleSubmit((data: { payments: DocumentPaymentForm[] }) => {
                        const payments = data.payments.filter((payment) => !(payment.total === 0 && payment.kupa_account_id === null));
                        documentForm.setValue("payments", payments);
                        resolve();
                    }, (errors) => {
                        reject(errors);
                    })();
                });
            }

            //add documents closing to documentForm
            if (!settings?.display_items && !settings?.display_works && settings?.display_payments) {

                await new Promise<void>((resolve, reject) => {
                    documentClosingForm.handleSubmit((data: { documents: DocumentClosingForm[] }) => {
                        const documentsClosing = data.documents.filter((document) => document.status !== 'פתוח');
                        documentForm.setValue("documents_closing", documentsClosing);
                        resolve();
                    }, (errors) => {
                        reject(errors);
                    })();
                });
            }

            //add deposits to documentForm
            await new Promise<void>((resolve, reject) => {
                documentDepositsForm.handleSubmit((data: { deposits: DocumentDepositAdd[] }) => {
                    documentForm.setValue("deposits", data.deposits);
                    resolve();
                }, (errors) => {
                    reject(errors);
                    return;
                })();
            });

            console.log("addDocument documentForm.getValues() after adding forms", documentForm.getValues());

            // If All Forms have no errors, submit.
            await new Promise<void>((resolve, reject) => {
                documentForm.handleSubmit((data) => {
                    onSubmitDocument(data);
                    resolve();
                }, (errors) => {
                    reject(errors);
                    return;
                })();
            });


        } catch (error) {
            console.error('An error occurred during Document submission:', error);
            // Handle the error accordingly
        }

    };



    // *********************************************************************************************


    // submit the document after validation
    const onSubmitDocument: SubmitHandler<DocumentEdit> = async (documentData) => {

        setIsSaving(true);

        console.log("onSubmitDocument documentData:", documentData);


        const editPromise = async () => {
            const response = await saveDocumentService(documentData.id, documentData);
            console.log("document added: ", response);

            setIsSaving(false);

            // navigate(`/documents`, {
            //     replace: true,
            //     state: null
            // });
        }

        // sending the form
        toast.promise(
            editPromise,
            {
                pending: 'עורך מסמך...',
                success: 'המסמך נערך בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        setIsSaving(false);
                        return toastErrorHandling(data)
                    }
                }
            }
        );
    }






    // *********************************************************************************************


    // ------------------- end of add document functions -------------------------- //


    // ------------------- component jsx -------------------------- //


    // *********************************************************************************************



    return (
        <StyledDocument>

            {documentsSettings && settings && structure && documentsStructure && documentNumber && incomes && kupaAccounts ? <>


                {/************************************************  כותרת מסמך  ************************************************/}

                <Title
                    method={method}
                    documentForm={documentForm}
                    browseDocument={browseDocument}
                    browseReference={browseReference}
                    editDocumentSettings={editDocumentSettings}
                    isDocumentClosed={isDocumentClosed}
                />

                {/************************************************  סוג מסמך, מספר, תאריך ושעה ************************************************/}

                <Header
                    method={method}
                    documentForm={documentForm}
                    documentsSettings={documentsSettings}
                    settings={settings}
                    structure={structure}
                    isDocumentClosed={isDocumentClosed}
                />


                {/************************************************  פרטי לקוח ורכב / חשבון  ************************************************/}

                <div className="details">

                    {/************************************************  פרטי לקוח  ************************************************/}

                    {settings?.display_customer && (
                        <Customer
                            method={method}
                            documentForm={documentForm}
                            settings={settings}
                            structure={structure}
                            browseCustomer={browseCustomer}
                            addCustomer={addCustomer}
                            editCustomer={editCustomer}
                            editAccountStructure={editAccountStructure}
                            isDocumentClosed={isDocumentClosed}
                        />
                    )}

                    {/************************************************  פרטי רכב  ************************************************/}

                    {settings?.display_car && (

                        <Car
                            method={method}
                            documentForm={documentForm}
                            structure={structure}
                            browseCar={browseCar}
                            addCar={addCar}
                            editCar={editCar}
                            editCarStructure={editCarStructure}
                            isDocumentClosed={isDocumentClosed}
                        />
                    )}

                    {/************************************************  פרטי בנק  ************************************************/}

                    {settings?.name === 'הפקדה' && (

                        <Bank
                            method={method}
                            documentForm={documentForm}
                            settings={settings}
                            structure={structure}
                            browseBank={browseBank}
                            addBank={addBank}
                            editBank={editBank}
                            editAccountStructure={editAccountStructure}
                            isDocumentClosed={isDocumentClosed}
                        />
                    )}
                </div>


                {/************************************************  עבודות  ************************************************/}

                {settings?.display_works && (

                    <Works
                        method={method}
                        worksForm={worksForm}
                        worksFieldArray={worksFieldArray}
                        structure={structure}
                        workRowIndex={workRowIndex}
                        browseWork={browseWork}
                        addWork={addWork}
                        editWork={editWork}
                        setWorkRowIndex={setWorkRowIndex}
                        addWorkLine={addWorkLine}
                        deleteRow={deleteRow}
                        editWorkStructure={editWorkStructure}
                        isFocused={isFocused}
                        isDocumentClosed={isDocumentClosed}
                    />
                )}


                {/************************************************  פריטים  ************************************************/}

                {settings?.display_items && (

                    <Items
                        method={method}
                        itemsForm={itemsForm}
                        itemsFieldArray={itemsFieldArray}
                        structure={structure}
                        itemRowIndex={itemRowIndex}
                        browseItem={browseItem}
                        addItem={addItem}
                        editItem={editItem}
                        setItemRowIndex={setItemRowIndex}
                        addItemLine={addItemLine}
                        deleteRow={deleteRow}
                        editItemStructure={editItemStructure}
                        isFocused={isFocused}
                        isDocumentClosed={isDocumentClosed}
                    />
                )}

                {/************************************************  הצגת חשבוניות בקבלה  ************************************************/}

                {settings.name === "קבלה" && (

                    <Invoices
                        method={method}
                        documentForm={documentForm}
                        documentClosingForm={documentClosingForm}
                        documentsClosingFieldArray={documentsClosingFieldArray}
                        settings={settings}
                        incomes={incomes}
                        calcDocument={calcDocument}
                        deleteRow={deleteRow}
                        setDocumentsToClose={setDocumentsToClose}
                        isDocumentClosed={isDocumentClosed}
                    />
                )}

                {/************************************************  בחירת אמצעי תשלום בקבלה  ************************************************/}

                {settings?.display_payments && kupaAccounts && (

                    <Payments
                        method={method}
                        paymentsForm={paymentsForm}
                        paymentsFieldArray={paymentsFieldArray}
                        kupaAccounts={kupaAccounts}
                        deleteRow={deleteRow}
                        isFocused={isFocused}
                        isDocumentClosed={isDocumentClosed}
                    />
                )}

                {/************************************************  הצגת שיקים בהפקדה  ************************************************/}

                {settings.name === "הפקדה" && (

                    <Deposit
                        method={method}
                        documentForm={documentForm}
                        documentDepositsForm={documentDepositsForm}
                        depositsFieldArray={depositsFieldArray}
                        kupaAccounts={kupaAccounts}
                        isSelectDepositsDialogOpen={isSelectDepositsDialogOpen}
                        isSelectCashDialogOpen={isSelectCashDialogOpen}
                        setIsSelectDepositsDialogOpen={setIsSelectDepositsDialogOpen}
                        setIsSelectCashDialogOpen={setIsSelectCashDialogOpen}
                        isDocumentClosed={isDocumentClosed}
                    />
                )}



                {/************************************************  הצגת בועת סיכום  ************************************************/}

                <Summary
                    method={method}
                    documentForm={documentForm}
                    settings={settings}
                />

                {/************************************************  כפתורים לשמירה או חזור  ************************************************/}

                <div className="buttons">
                    <Button onClick={saveDocument} variant='contained' color="primary" disabled={isSaving || isDocumentClosed}>שמירה</Button>
                    <Button variant='outlined' color="primary" onClick={()=>{navigate("/documents")}}>חזור</Button>
                </div>


                {/************************************************  הצגת טעינה  ************************************************/}

            </>
                :
                <div className="loading">
                    <div className="head">
                        <Skeleton variant="rectangular" height={60} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />
                    </div>
                    <div className="details">
                        <div className="customer">
                            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />
                        </div>
                        <div className="car">
                            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />
                        </div>
                    </div>
                    <div className="items">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />
                    </div>
                    <div className="payment">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: theme.colors.section_color }} />
                    </div>

                </div>
            }

            {/* <DevTool control={documentForm.control} /> */}


        </StyledDocument >
    )
}

export default EditDocument