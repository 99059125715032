import { IconButton, Tooltip, Typography } from "@mui/material"
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import { DocumentAdd, DocumentEdit } from "../../../../models/document";
import { UseFormReturn } from "react-hook-form";
import SettingsIcon from '@mui/icons-material/Settings';
import { DocumentStructure } from "../../../../models/document-structure";
import MuiInput from "../../../../components/mui-input/MuiInput";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';


// theme
import { useTheme } from "@emotion/react";



interface CarProps {

    method: "add" | "edit";

    documentForm: UseFormReturn<DocumentAdd> | UseFormReturn<DocumentEdit>;
    structure: DocumentStructure[];

    browseCar: () => void;
    addCar: () => void;
    editCar: () => void;

    editCarStructure: () => void;

    isDocumentClosed?: boolean;
}




function Car({ method, isDocumentClosed, documentForm, structure, browseCar, addCar, editCar, editCarStructure }: CarProps) {


    const theme = useTheme();

    if (method === "add") {

        return (
            <div className="car">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            פרטי רכב:
                        </Typography>
                        <Tooltip title="בחירת כרטיס רכב מתוך דפדוף" placement="top">
                            <IconButton onClick={browseCar} sx={{ color: theme.colors.main_color }}>
                                <AutoStoriesIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="הוספת כרטיס רכב" placement="top">
                            <span>
                                <IconButton onClick={addCar} disabled={!!documentForm.getValues().car?.id} sx={{ color: theme.colors.main_color }}>
                                    <BookmarkAddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="עריכת כרטיס רכב" placement="top">
                            <span>
                                <IconButton onClick={editCar} disabled={!documentForm.getValues().car?.id} sx={{ color: theme.colors.main_color }}>
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="הגדרת שדות רכב" placement="top">
                            <IconButton onClick={editCarStructure} sx={{ color: theme.colors.main_color }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="inputs">
                    {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                        return (field.type === "רכב" && field.show_form) ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm as UseFormReturn<DocumentAdd>} showRequired /> : null
                    })}
                </div>
            </div>
        )
    }

    else if (method === "edit") {

        return (
            <div className="car">
                <div className="title">
                    <div>
                        <Typography variant="body1" component="span">
                            פרטי רכב:
                        </Typography>
                        <Tooltip title="בחירת כרטיס רכב מתוך דפדוף" placement="top">
                            <span>
                                <IconButton onClick={browseCar} sx={{ color: theme.colors.main_color }} disabled={isDocumentClosed}>
                                    <AutoStoriesIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="הוספת כרטיס רכב" placement="top">
                            <span>
                                <IconButton onClick={addCar} disabled={!!documentForm.getValues().car?.id || isDocumentClosed} sx={{ color: theme.colors.main_color }}>
                                    <BookmarkAddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="עריכת כרטיס רכב" placement="top">
                            <span>
                                <IconButton onClick={editCar} disabled={!documentForm.getValues().car?.id || isDocumentClosed} sx={{ color: theme.colors.main_color }}>
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="הגדרת שדות רכב" placement="top">
                            <IconButton onClick={editCarStructure} sx={{ color: theme.colors.main_color }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="inputs">
                    {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                        return (field.type === "רכב" && field.show_form) ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm as UseFormReturn<DocumentEdit>} showRequired disabled={isDocumentClosed} /> : null
                    })}
                </div>
            </div>
        )
    }


}

export default Car