// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// ==============================|| AUTH BLUR BACK SVG ||============================== //

function Metal() {

    // const theme = useTheme();

    const color1 = "#3a57a3";
    const color2 = "#b2519c";


    return (
        <Box
            sx={{
                position: 'absolute',
                right: 0,
                bottom: "10%", 
                filter: 'blur(25px)',
                // zIndex: -1,
                transform: 'inherit',
            }}
        >
            <svg width="350px" viewBox="0 0 220 220">
                <path   
                    d="M116.2.1c0,3.5,0,6.9-.1,10.4h-4.4c-.7,0-1,.2-1,1,.1,1.5,0,2.9,0,4.4h-56.6c-1.2,0-1.5-.3-1.4-1.4.1-.9.1-1.9,0-2.9-.1-.8.2-1.1,1-1,1.1.1,2.2,0,3.2,0,.8,0,1.1-.2,1-1-.1-1.2,0-2.3,0-3.5,0-.6.2-.8.8-.8,1.1,0,2.2-.1,3.4,0,1,.1,1.2-.3,1.2-1.2-.1-1.3,0-2.7,0-4,17.8,0,35.3,0,52.9,0Z"
                    fill={color1}
                />
                <path
                    d="M205.7,15.9h-41c-.9,0-1.2-.2-1.2-1.1.1-1.3-.4-3,.2-3.9.7-.9,2.5-.3,3.8-.3,1.1,0,1.3-.4,1.2-1.3-.1-1,0-2,0-3-.1-.8.2-1.1,1-1,1.1.1,2.2,0,3.4,0,.6,0,.9-.1.9-.9-.1-1.5-.1-2.9-.1-4.4h31.7c.1,5.4.1,10.6.1,15.9Z"
                    fill={color1}
                />
                <path
                    d="M205.7,15.9V.1h15.8v14.8c0,.7-.1,1-.9,1h-14.9Z"
                    fill={color2}
                />
                <path
                    d="M110.8,42.2c-.5,0-.9.1-1.4.1H28.1c-1.3,0-1.7-.4-1.6-1.7.1-.9.1-1.9,0-2.8,0-.7.2-.9.9-.9,1,0,2-.1,3,0,1.3.2,1.6-.4,1.5-1.6-.1-1,0-2,0-3,0-.6.2-.8.8-.7h3.5c.8,0,1-.3,1-1-.1-1.1,0-2.2,0-3.4,0-.7.2-.9.9-.9,1,0,2-.1,3,0,1.3.1,1.7-.4,1.5-1.6-.1-.9,0-1.9,0-2.8,0-.8.2-1,1-.9h67.5c-.3,7.2-.3,14.2-.3,21.2Z"
                    fill={color1}
                />
                <path
                    d="M116.2,52.7h14.6c.8,0,1.2-.2,1.1-1.1-.1-1,.1-2,0-3s.3-1.2,1.2-1.2h30.3c0,1.3-.1,2.7,0,4,.1.9-.3,1.2-1.2,1.2-2.8-.1-5.6,0-8.4,0-.8,0-1.1.2-1.1,1,.1,1.1,0,2.2,0,3.4,0,.6-.2.8-.8.8h-3.4c-.7,0-1,.3-1,1v3.4c0,.7-.2.9-.9.9h-3.4c-.7,0-1,.2-1,1v4.4h-1c-20.2,0-40.4,0-60.6.1-1.3,0-1.6-.3-1.5-1.6.1-4.8,0-9.5,0-14.2.2-1.7.1-3.5.1-5.2,3.1,0,6.1,0,9.2-.1h20.9c.5,0,1,0,1.4.1v9.6c0,.7.2,1,.9,1,1.2-.1,2.4,0,3.6,0,.7,0,.9-.2.9-.9.1-1.6.1-3.1.1-4.6Z"
                    fill={color1}
                />
                <path
                    d="M73.9,52.7c-1.7,0-3.4.1-5.2.1-1.6,0-3.1,0-4.7-.1-.6,0-.8.2-.8.8v3.4c0,.8-.2,1-1,1h-7.8q-1.6,0-1.6,1.7v8.9c-.4,0-.8.1-1.2.1H1.4c-1,0-1.4-.2-1.4-1.3.1-2.8.1-5.5,0-8.3,0-.8.2-1.1,1-1,1.1.1,2.2,0,3.2,0,.8,0,1.1-.2,1-1-.1-1,0-2.1,0-3.1-.1-.9.2-1.2,1.1-1.2,1.1.1,2.2,0,3.4,0,.6,0,.9-.2.8-.8v-3.5c0-.8.2-1,1-.9h61.4c.3,0,.6,0,.9.1.1,1.7.1,3.4.1,5.1Z"
                    fill={color1}
                />

                <path
                    d="M205.8,42.2c-.4.2-.9.1-1.3.1h-60.6c-1.2,0-1.6-.2-1.5-1.5.2-1.3-.5-2.9.3-3.7.7-.7,2.4-.2,3.6-.2,1.1.1,1.6-.2,1.5-1.5-.1-1.2-.6-2.9.2-3.6.7-.6,2.3-.3,3.5-.2,1.2.1,1.5-.3,1.5-1.5-.1-1.2-.6-2.9.2-3.6.7-.6,2.3-.3,3.5-.2,1.1.1,1.7-.2,1.5-1.5-.1-.9,0-1.8,0-2.7-.1-.9.3-1.2,1.2-1.2h46.4c-.1,7.3,0,14.3,0,21.3Z"
                    fill={color1}
                />
                <path
                    d="M37,152.7H1.3c-.8,0-1.1-.1-1.1-1v-24.2c0-.6.1-1,.9-1,12,0,24,.1,35.9.1v26.1Z"
                    fill={color1}
                />
                <path
                    d="M116,94.7h-35.4c-.9,0-1.3-.1-1.3-1.2.1-6.2,0-12.5,0-18.8,0-.8.1-1.1,1-1.1h50.9c.2,0,.3,0,.5.1,0,1.4-.1,2.8,0,4.3,0,.8-.2,1.1-1,1.1-1-.1-2,0-3,0-.9-.1-1.3.2-1.3,1.2.1,1,0,2,0,3,0,.6-.2.8-.8.8h-3.1c-.9-.1-1.4.2-1.3,1.2.1,1,0,2.1,0,3.1.1.8-.2,1.2-1.1,1.1-1-.1-2.1,0-3.1,0-.9-.1-1.3.2-1.3,1.2.3,1.4.3,2.7.3,4Z"
                    fill={color1}
                />
                <path
                    d="M37,94.8c-5,0-10,0-15,.1H1.4c-1,0-1.2-.2-1.2-1.2v-18.8c0-.7-.1-1.2,1-1.2h36c-.2,7.1-.2,14.1-.2,21.1Z"
                    fill={color1}
                />
                <path
                    d="M37,121.1c-6.3,0-12.6,0-18.9.1H1.4c-1,0-1.2-.2-1.2-1.2v-18.8c0-.6-.1-1.1.8-1.1,12,0,24,.1,36.1.1-.1,7-.1,13.9-.1,20.9Z"
                    fill={color1}
                />
                <path
                    d="M205.7,152.7c-6.8,0-13.6,0-20.5.1-.6,0-.8-.1-.8-.8v-24.7c0-.6.2-.8.8-.8,6.8,0,13.6.1,20.5.1v26.1Z"
                    fill={color1}
                />
                <path
                    d="M205.7,94.8c-6.7,0-13.5,0-20.2.1-.6,0-.8-.2-.8-.8v-19.5c0-.6.2-.8.8-.8,6.7,0,13.5,0,20.2.1v20.9Z"
                    fill={color1}
                />
                <path
                    d="M205.7,68.5c-6.7,0-13.4,0-20.1.1-.8,0-.9-.2-.9-.9v-19.2c0-.6,0-1,.8-.9,6.7,0,13.5,0,20.2.1v20.8Z"
                    fill={color1}
                />
                <path
                    d="M205.7,121.1c-6.7,0-13.5,0-20.2.1-.7,0-.9-.2-.9-.9v-19.3c0-.7.2-.9.9-.9,6.7,0,13.5,0,20.2.1v20.9Z"
                    fill={color1}
                />
                <path
                    d="M37,152.7v-26.1c5,0,10,0,15-.1.7,0,.8.3.8.9v24.4c0,.6-.2.9-.9.9h-14.9Z"
                    fill={color2}
                />
                <path
                    d="M205.7,152.7v-26.1c5,0,10,0,15-.1.6,0,.8.3.8.9v24.6c0,.6-.2.8-.8.8-4.9-.1-10-.1-15-.1Z"
                    fill={color2}
                />
                <path
                    d="M116,94.7v-4c-.1-1,.3-1.3,1.3-1.2,1,.1,2.1,0,3.1,0,.9.1,1.1-.3,1.1-1.1-.1-1,0-2.1,0-3.1-.1-1,.3-1.3,1.3-1.2,1,.1,2.1,0,3.1,0,.6,0,.9-.2.8-.8,0-1,.1-2,0-3s.3-1.3,1.3-1.2c1,.1,2,0,3,0,.8.1,1-.3,1-1.1v-4.3c5.1,0,10.2-.1,15.3-.1.6,0,.7.3.7.8v3.9c0,.6-.2.7-.8.7-1.1,0-2.2.1-3.4,0-1-.1-1.3.3-1.2,1.2.1,1,0,2.1,0,3.1,0,.7-.2.9-.9.9-1,0-2.1.1-3.1,0-1.1-.1-1.3.3-1.3,1.3.1,1,0,2,0,3,0,.7-.2,1-.9,1-1.2-.1-2.3,0-3.5,0-.6,0-.9.2-.8.8v3.6c0,.6-.1.9-.8.9h-14.9c-.2.1-.3,0-.4-.1Z"
                    fill={color2}
                />
                <path
                    d="M142.4,68.5v-4.4c0-.7.2-1,1-1h3.4c.7,0,.9-.2.9-.9v-3.4c0-.7.3-1,1-1h3.4c.6,0,.9-.2.8-.8v-3.4c0-.8.3-1,1.1-1h8.4c.9,0,1.2-.2,1.2-1.2-.1-1.3,0-2.7,0-4,5.1,0,10.2,0,15.3-.1.6,0,.8.2.8.8,0,1.2-.1,2.3,0,3.5.1.8-.3,1-1.1,1h-8.4c-.9,0-1.1.3-1.1,1.2,0,1.3.6,3-.2,3.9-.8.8-2.5.2-3.8.2-1,0-1.3.3-1.2,1.2.1,1,0,2,0,3,.1.9-.3,1.2-1.2,1.1-1-.1-2,0-3,0-.9-.1-1.3.2-1.2,1.1.1,1.1,0,2.2,0,3.2,0,.7-.2.9-.9.9-5.2.1-10.2.1-15.2.1Z"
                    fill={color2}
                />
                <path
                    d="M142.4,68.5v-4.4c0-.7.2-1,1-1h3.4c.7,0,.9-.2.9-.9v-3.4c0-.7.3-1,1-1h3.4c.6,0,.9-.2.8-.8v-3.4c0-.8.3-1,1.1-1h8.4c.9,0,1.2-.2,1.2-1.2-.1-1.3,0-2.7,0-4,5.1,0,10.2,0,15.3-.1.6,0,.8.2.8.8,0,1.2-.1,2.3,0,3.5.1.8-.3,1-1.1,1h-8.4c-.9,0-1.1.3-1.1,1.2,0,1.3.6,3-.2,3.9-.8.8-2.5.2-3.8.2-1,0-1.3.3-1.2,1.2.1,1,0,2,0,3,.1.9-.3,1.2-1.2,1.1-1-.1-2,0-3,0-.9-.1-1.3.2-1.2,1.1.1,1.1,0,2.2,0,3.2,0,.7-.2.9-.9.9-5.2.1-10.2.1-15.2.1Z"
                    fill={color2}
                />
                <path 
                    d="M37,94.8v-21c5,0,10,0,15-.1.6,0,.8.2.8.8v19.6c0,.6-.2.8-.8.8-4.9,0-10,0-15-.1Z"
                    fill={color2}
                />
                <path 
                    d="M205.7,94.8v-21c5,0,10,0,15-.1.6,0,.8.2.8.8v19.5c0,.6-.2.8-.8.8-4.9.1-10,.1-15,0Z"
                    fill={color2} 
                />
                <path 
                    d="M205.7,121.1v-20.9c5,0,10,0,15-.1.6,0,.8.2.8.8v19.4c0,.6-.2.8-.8.8-4.9,0-10,0-15,0Z"
                    fill={color2}
                />
                <path 
                    d="M205.7,68.5v-20.9c5,0,10,0,15-.1.7,0,.9.3.9.9v19.3c0,.7-.2.9-.9.9-5-.1-10-.1-15-.1Z"
                    fill={color2}
                />
                <path 
                    d="M205.8,42.2v-21c5,0,10,0,15-.1.7,0,.9.3.9.9v19.1c0,1-.5,1-1.2,1-5,.1-9.9.1-14.7.1Z"
                    fill={color2}
                />
                <path 
                    d="M37,121.1v-20.9c5,0,10,0,15-.1.6,0,.8.2.8.8v19.4c0,.6-.2.8-.8.8-4.9,0-10,0-15,0Z"
                    fill={color2}
                />
                <path 
                    d="M52.9,68.5v-8.9q0-1.7,1.6-1.7h7.8c.8,0,1.1-.2,1-1-.1-1.1,0-2.2,0-3.4,0-.6.2-.8.8-.8,1.6,0,3.1.1,4.7.1,0,3.1-.1,6.3,0,9.4,0,.9-.2,1.2-1.2,1.2-1-.1-2.1,0-3.1,0-.8,0-1.1.2-1,1,.1,1.1,0,2.2,0,3.2,0,.7-.1,1-.9,1-3.3-.1-6.5-.1-9.7-.1Z"
                    fill={color2} 
                />
                <path 
                    d="M110.8,42.2v-21.1c1.5,0,3,0,4.5-.1.7,0,.9.2.9.9v19.5c0,.5-.1.9-.8.9-1.5,0-3.1,0-4.6-.1Z"
                    fill={color2}
                />
                <path 
                    d="M116.2,52.7c0,1.5-.1,3,0,4.5,0,.7-.2.9-.9.9-1.2,0-2.4-.1-3.6,0-.8,0-.9-.3-.9-1v-9.6c1.5,0,3,0,4.5-.1.8,0,.9.3.9,1v4.3Z"
                    fill={color2} 
                />
                <path 
                    d="M110.8,15.9v-4.4c0-.7.2-1,1-1h4.4c0,1.5,0,3.1.1,4.7,0,.6-.2.8-.8.8-1.6-.1-3.2-.1-4.7-.1Z"
                    fill={color2}
                />
                <path 
                    d="M73.9,52.7v-5.1c1.8-.1,3.6-.1,5.4,0,0,1.7.1,3.5-.1,5.2-1.8-.1-3.5-.1-5.3-.1Z"
                    fill={color2}
                />
                {/* <path
                    d="M-358.39 358.707L-293.914 294.23L-293.846 294.163H-172.545L-220.81 342.428L-233.272 354.889L-282.697 404.314L-276.575 410.453L0.316589 687.328L283.33 404.314L233.888 354.889L230.407 351.391L173.178 294.163H294.48L294.547 294.23L345.082 344.765L404.631 404.314L0.316589 808.629L-403.998 404.314L-358.39 358.707ZM0.316589 0L233.938 233.622H112.637L0.316589 121.301L-112.004 233.622H-233.305L0.316589 0Z"
                    fill={theme.palette.primary.light}
                />
                <path
                    d="M-516.39 358.707L-451.914 294.23L-451.846 294.163H-330.545L-378.81 342.428L-391.272 354.889L-440.697 404.314L-434.575 410.453L-157.683 687.328L125.33 404.314L75.8879 354.889L72.4068 351.391L15.1785 294.163H136.48L136.547 294.23L187.082 344.765L246.631 404.314L-157.683 808.629L-561.998 404.314L-516.39 358.707ZM-157.683 0L75.9383 233.622H-45.3627L-157.683 121.301L-270.004 233.622H-391.305L-157.683 0Z"
                    fill={theme.palette.success.light}
                    opacity="0.6"
                />
                <path
                    d="M-647.386 358.707L-582.91 294.23L-582.842 294.163H-461.541L-509.806 342.428L-522.268 354.889L-571.693 404.314L-565.571 410.453L-288.68 687.328L-5.66624 404.314L-55.1082 354.889L-58.5893 351.391L-115.818 294.163H5.48342L5.5507 294.23L56.0858 344.765L115.635 404.314L-288.68 808.629L-692.994 404.314L-647.386 358.707ZM-288.68 0L-55.0578 233.622H-176.359L-288.68 121.301L-401 233.622H-522.301L-288.68 0Z"
                    fill={"yellow"}
                    opacity={'1'}
                /> */}
            </svg>
        </Box>
    );
}

export default Metal;